// This method will check whether 2 bjects are equal or not
export const isObjectEqual = (object1, object2) => {
    return JSON.stringify(object1) === JSON.stringify(object2);
};

// This method will format the date
export const formatDate = (date) => {
    let splitDate = date?.split('-');
    if (splitDate?.length > 0) {
        splitDate[0] = splitDate?.splice(2, 1, splitDate[0])[0];
    }
    return splitDate?.join('-');
};

export const getFileNameFromUrl = (fileUrl) => {
    if (!fileUrl) return ''; // Handle null/undefined input

    const decodedUrl = decodeURIComponent(fileUrl); // Decode URL
    const fileName = decodedUrl?.split('/').pop(); // Extract file name from path
    return fileName?.substring(fileName?.indexOf('_') + 1)?.split('?')[0]; // Remove prefix & query params
};
