import AttachFileIcon from '@mui/icons-material/AttachFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, Tooltip } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks';
import awardImage from '../../../images/award_icon.png';
import messageIcon from '../../../images/message_icon_light.svg';
import { addNewMailChimpTag, undoRejectedCompletedApplicant } from '../../../services/app/api';
import { getStudentLevel } from '../../../services/app/storage';
import { isBoolean } from '../../../services/core/types';
import { setPage, setScrollBarPosition } from '../../../store/appliedCandidatesSlice';
import { setPrintOutUserData } from '../../../store/genericSlice';
import { setSelectedUser } from '../../../store/messageSlice';
import { convertDate } from '../../../utils/GenericFunctions';
import { GRADUATE_PROGRAM } from '../../../utils/GetConstants';
import * as labelConst from '../../../utils/GetLabels';
import mailchimpTags from '../../../utils/mailchimpTags';
import { EmptyApplicationResult } from '../../Common/EmptyApplicationResult';
import BubbleTooltip from '../../UI/BubbleTooltip';
import Button from '../../UI/Button';
import Headshot from '../../UI/Headshot';
import MuiModal from '../../UI/MuiModal';
import Typography from '../../UI/Typography';
import { SponsorSearchPagination } from '../Search/SponsorSearchPagination';
import PaymentForm from './PaymentForm';

export function AppliedCandidatesList({
    applicantData,
    profileId,
    statusCode,
    loading,
    status,
    selectVal,
    checked,
    onHandleChange,
    questions,
    toggled,
    getButtonTabValue,
    oppo_type,
}) {
    const page = useSelector((state) => state.appliedCandidatesSlice.page);
    const DATA_PER_PAGE = 10;
    const startIndex = (page - 1) * DATA_PER_PAGE;
    const endIndex = startIndex + DATA_PER_PAGE;
    const allApplicants = applicantData.applicants;
    const [applicants, setApplicants] = useState(allApplicants?.slice(startIndex, endIndex));
    const totalPages = Math.ceil(applicantData?.applicants?.length / DATA_PER_PAGE);
    const snackbar = useSnackbar();
    const dispatch = useDispatch();
    const auth = useAuth();
    const navigate = useNavigate();
    const [openDialogueBox, setOpenDialogueBox] = useState(false);
    const crossStudentLimit = applicantData?.applicants?.length === applicantData?.students_limit;
    const { value: valueType } = applicantData?.metadata.find(({ key }) => key === 'opp_type');
    const specificOpportunity = valueType === 'Students to Sponsor' || valueType === 'Scholars';

    const [selectedCheckItems, setSelectedCheckItems] = useState([]);
    const [checkAllItems, setCheckAllItems] = useState(false);

    useEffect(() => {
        if (selectedCheckItems.length === allApplicants.length) {
            setCheckAllItems(true);
        } else {
            setCheckAllItems(false);
        }
    }, [selectedCheckItems]);

    const handleCheckboxItemChange = (event, id) => {
        if (event.target.checked) {
            setSelectedCheckItems((prev) => [...prev, id]);
        } else {
            setSelectedCheckItems((prev) => prev.filter((itemId) => itemId !== id));
        }
    };

    const handleCheckAllItemsChange = (event) => {
        const { checked } = event.target;
        setCheckAllItems(checked);
        if (checked) {
            setSelectedCheckItems(allApplicants.map((item) => item.profile.id));
        } else {
            setSelectedCheckItems([]);
        }
    };

    const getMatchingObjects = (objects, strings) => {
        return objects.filter((obj) => strings.includes(obj.profile.id));
    };
    const matchingObjects = getMatchingObjects(applicants, selectedCheckItems);
    useEffect(() => {
        dispatch(setPrintOutUserData(matchingObjects));
    }, [selectedCheckItems]);
    if (applicants?.length <= 0 && !loading) {
        return (
            <div className="">
                <EmptyApplicationResult
                    text={`No ${statusCode === 'pending' ? 'Applied' : statusCode} Applicants Found`}
                />
            </div>
        );
    }

    async function onAcceptOrRejectClicked({
        id,
        approve,
        e,
        value,
        studentEmail = '',
        studentUserName = '',
        status = '',
    }) {
        if (crossStudentLimit && status === 'Accepted' && auth?.askForSubscription() && specificOpportunity) {
            return setOpenDialogueBox(true);
        }
        try {
            await undoRejectedCompletedApplicant({
                id: profileId,
                applicationId: id,
                payload: {
                    status: status,
                },
            });
            const opportunityType = applicantData?.metadata?.flatMap((item) =>
                item?.key === 'opp_type' ? [item?.value] : []
            )[0];
            let sponsorTags = {};
            let studentTags = {};
            let extraInfoSponsor = `${applicantData?.title}-${studentUserName}-${convertDate(
                applicantData?.created_at,
                'YYYY-MM-DD'
            )}`;
            let extraInfoStudent = `${applicantData?.title}-${auth?.getUserName()}-${convertDate(
                applicantData?.created_at,
                'YYYY-MM-DD'
            )}`;
            if (approve) {
                sponsorTags = {
                    Mentee: [`${mailchimpTags.SPONSOR_ACCEPTS_MENTEE}-${extraInfoSponsor}`],
                    Scholars: [`${mailchimpTags.SPONSOR_ACCEPTS_STUDENT_SCHOLAR}-${extraInfoSponsor}`],
                };
                studentTags = {
                    Mentee: [`${mailchimpTags.STUDENT_ACCEPTED_FOR_MENTORSHIP}-${extraInfoStudent}`],
                    Scholars: [`${mailchimpTags.STUDENT_ACCEPTED_FOR_SCHOLARSHIP}-${extraInfoStudent}`],
                };
            } else {
                sponsorTags = {
                    Mentee: [`${mailchimpTags.SPONSOR_REJECTS_MENTEE}-${extraInfoSponsor}`],
                    Scholars: [`${mailchimpTags.SPONSOR_REJECTS_STUDENT_SCHOLAR}-${extraInfoSponsor}`],
                };
                studentTags = {
                    Mentee: [`${mailchimpTags.STUDENT_REJECTED_FOR_MENTORSHIP}-${extraInfoStudent}`],
                    Scholars: [`${mailchimpTags.STUDENT_REJECTED_FOR_SCHOLARSHIP}-${extraInfoStudent}`],
                };
            }
            if (studentTags[opportunityType]) {
                const mailchimpPayload = {
                    profileId: auth?.getProfileId(),
                    data: {
                        email: studentEmail,
                        tags: studentTags[opportunityType],
                    },
                };
                await addNewMailChimpTag(mailchimpPayload).catch(() => {});
            }
            if (sponsorTags[opportunityType]) {
                const mailchimpPayload = {
                    profileId: auth?.getProfileId(),
                    data: {
                        email: auth?.getEmail(),
                        tags: sponsorTags[opportunityType],
                    },
                };
                await addNewMailChimpTag(mailchimpPayload).catch(() => {});
            }

            const text = approve ? 'Applicant Accepted successfully' : 'Applicant Rejected successfully';
            snackbar.success(text);
            onHandleChange(e, value);
        } catch (error) {
            console.log(error);
            snackbar.error("Error! Couldn't perform action, please try again later.");
        }
    }

    async function undoRejectedOrCompleted({ id, status, role = 'Student', username = '', teamId = '' }) {
        if (status === 'Reviewed') {
            if (role === 'Student') {
                return navigate(`/${role.toLowerCase()}/${username}`, { state: { from: 'Candidates' } });
            } else if (role === 'High School' || role === 'College') {
                return navigate(`/team/${teamId}`, { state: { from: 'Candidates' } });
            }
        }
        try {
            await undoRejectedCompletedApplicant({
                id: profileId,
                applicationId: id,
                payload: {
                    status: status,
                },
            });
            if (status !== 'Reviewed') {
                snackbar.success('Applicant moved to Applied');
            }
            onHandleChange(null, status === 'Completed' ? 3 : 0);
        } catch (error) {
            console.log(error);
            snackbar.error("Error! Couldn't perform action, please try again later.");
        }
    }

    function onPageChange(event, newValue) {
        dispatch(setScrollBarPosition(1));
        dispatch(setPage(newValue));
        const startIndex = (newValue - 1) * DATA_PER_PAGE;
        const endIndex = startIndex + DATA_PER_PAGE;
        setApplicants(allApplicants.slice(startIndex, endIndex));
    }

    return (
        <div className="divide-y-1 divide-slate-200">
            {applicants?.map((applicant) => {
                return applicant?.user?.sub_role === 'Leader' ? (
                    <LeaderApplicantCard
                        key={applicant.application.id}
                        statusCode={statusCode}
                        onAcceptOrRejectClicked={onAcceptOrRejectClicked}
                        checked={checked}
                        undoRejectedOrCompleted={undoRejectedOrCompleted}
                        {...applicant}
                        questions={questions}
                        setOpenDialogueBox={setOpenDialogueBox}
                        openDialogueBox={openDialogueBox}
                    />
                ) : (
                    <ApplicantCard
                        key={applicant.application.id}
                        statusCode={statusCode}
                        onAcceptOrRejectClicked={onAcceptOrRejectClicked}
                        checked={checked}
                        undoRejectedOrCompleted={undoRejectedOrCompleted}
                        {...applicant}
                        questions={questions}
                        setOpenDialogueBox={setOpenDialogueBox}
                        openDialogueBox={openDialogueBox}
                        auth={auth}
                        toggled={toggled}
                        handleCheckAllItemsChange={handleCheckAllItemsChange}
                        handleCheckboxItemChange={handleCheckboxItemChange}
                        selectedCheckItems={selectedCheckItems}
                        getButtonTabValue={getButtonTabValue}
                        oppo_type={oppo_type}
                    />
                );
            })}

            <div className="m-2">
                <div className="container flex justify-end">
                    <div className="flex flex-row justify-center items-center gap-4">
                        <label className="flex flex-row  gap-1 justify-center items-center cursor-pointer">
                            <Checkbox size="small" checked={checkAllItems} onChange={handleCheckAllItemsChange} />
                            <Typography className="text-[16px] mb-1 font-normal font-geometric">
                                Select All to Print
                            </Typography>
                        </label>
                    </div>
                </div>
                <SponsorSearchPagination page={page} totalPages={totalPages} onPageChange={onPageChange} />
            </div>
        </div>
    );
}

const YesNoQuestionsAccordian = ({ questions, application }) => {
    return (
        <div className="mt-2 sm:w-full ">
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className="text-primary font-bold font-geometric">Yes/No Questions </Typography>
                </AccordionSummary>
                <hr />
                <AccordionDetails>
                    {questions.question_1 && (
                        <>
                            <div className="flex items-center justify-between  my-3 ">
                                <Typography className="font-geometric font-medium">{questions.question_1}</Typography>
                                <Typography
                                    className={`font-geometric text-left w-8 font-bold ${
                                        application.answer_1 === 'Yes' ? 'text-green-500' : 'text-red-500'
                                    }`}>
                                    {application.answer_1 === null || application.answer_1 === 'undefined'
                                        ? 'NA'
                                        : application.answer_1}
                                </Typography>
                            </div>
                            <hr />
                        </>
                    )}
                    {questions.question_2 && (
                        <>
                            <div className="flex items-center justify-between  my-3 ">
                                <Typography className="font-geometric font-medium">{questions.question_2}</Typography>
                                <Typography
                                    className={`font-geometric text-left w-8  font-bold ${
                                        application.answer_2 === 'Yes' ? 'text-green-500' : 'text-red-500'
                                    }`}>
                                    {application.answer_2 === null || application.answer_2 === 'undefined'
                                        ? 'NA'
                                        : application.answer_2}
                                </Typography>
                            </div>
                            <hr />
                        </>
                    )}
                    {questions.question_3 && (
                        <>
                            <div className="flex items-center justify-between mt-3 ">
                                <Typography className="font-geometric font-medium">{questions.question_3}</Typography>
                                <Typography
                                    className={`font-geometric text-left w-8 font-bold ${
                                        application.answer_3 === 'Yes' ? 'text-green-500' : 'text-red-500'
                                    }`}>
                                    {application.answer_3 === null || application.answer_3 === 'undefined'
                                        ? 'NA'
                                        : application.answer_3}
                                </Typography>
                            </div>
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

function ApplicantCard({
    college,
    high_school,
    profile,
    user,
    statusCode,
    onAcceptOrRejectClicked,
    undoRejectedOrCompleted,
    questions,
    application,
    setOpenDialogueBox,
    openDialogueBox,
    auth,
    toggled,
    handleCheckboxItemChange,
    selectedCheckItems,
    getButtonTabValue,
    oppo_type,
}) {
    const navigate = useNavigate();
    const { first_name, last_name, student_level, role } = user || {};
    const {
        profile_photo,
        location,
        key_stats,
        username,
        profile_completion_percentage: profileCompletion,
        profile_strength_percentage: profilesStrength,
        id: profileID,
    } = profile || {};
    const { name: highSchoolName, graduation_year: highSchoolGradYear } = high_school || {};
    const { name: collegeName, graduation_year: collegeGradYear } = college || {};
    const dispatch = useDispatch();
    const handleGoToMessage = () => {
        let data = { ...user, user_id: user.id, ...profile, profile_id: profile.id };
        dispatch(setSelectedUser(data));
        navigate('../messages');
    };
    const application_id = application.id;
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const handlePaymentModal = () => setOpenPaymentModal(!openPaymentModal);
    const [confirmFinishModal, setConfirmFinishModal] = useState(false);
    const formattedSubmittedDate = format(fromUnixTime(Number(application?.created_at)), 'MMM dd yyyy');
    return (
        <div key={application_id} className="max-w-[1100px] py-3 sm:p-7">
            <PaymentForm openPaymentModal={openPaymentModal} handlePaymentModal={handlePaymentModal} />
            <MuiModal
                open={openDialogueBox}
                title={'Confirmation Box'}
                message={labelConst.LIMIT_MESSAGE}
                handleConfirm={() => navigate(`/sponsor/${username}/scholarships`)}
                handleClose={() => setOpenDialogueBox(false)}
            />
            <Grid container columnGap={'20px'} className="relative">
                <div className="absolute -right-5 flex flex-row  gap-1 justify-center items-center cursor-pointer -top-7">
                    <Checkbox
                        checked={selectedCheckItems.includes(profileID)}
                        onChange={(e) => handleCheckboxItemChange(e, profileID)}
                    />
                    <Typography className="text-[16px] mb-1 font-normal font-geometric">Print Application</Typography>
                </div>
                <Grid
                    item
                    md={2}
                    className={`flex ${isMobile ? 'flex-row items-center gap-5' : ''} justify-center items-center`}>
                    <div className="flex items-center flex-col">
                        <div className={`relative ${toggled ? 'blur-[10px]' : ''}`}>
                            <Headshot url={profile_photo} border={'4'} size={isMobile ? 75 : 150} user={'Student'} />
                            {profilesStrength === 100 && profileCompletion === 100 && (
                                <img
                                    src={awardImage}
                                    alt="awardImage"
                                    className="absolute h-6 w-6 bottom-0 right-0 sm:h-8 sm:w-8 sm:bottom-1 sm:right-1"
                                />
                            )}
                            {/* <Typography className="block sm:hidden text-center font-geometric font-medium text-primary sm:py-1 py-[1px]">
                                P.S :- {profileCompletion}%
                            </Typography>
                            <Typography className="sm:block hidden text-center font-geometric font-medium text-primary sm:py-1 py-[1px]">
                                Profile Status :- {profileCompletion}%
                            </Typography> */}
                        </div>
                        <div className="m-2">
                            {auth?.getSubRole() !== 'Storefront' && (
                                <Button
                                    onClick={handleGoToMessage}
                                    startIcon={
                                        <img src={messageIcon} alt="message_icon" height={'90%'} width={'90%'} />
                                    }
                                    variant="contained"
                                    size="small"
                                    color="primary">
                                    Message
                                </Button>
                            )}
                            {user.enroll_id && (
                                <Typography className="text-12 font-medium my-1">
                                    {`Enroll Id: `}
                                    <span className="font-bold">{user.enroll_id}</span>
                                </Typography>
                            )}
                        </div>

                        {application.files?.length > 0 && (
                            <div className="m-2 mx-auto">
                                {application.files.map((fileUrl, index) => {
                                    const decodedUrl = decodeURIComponent(fileUrl);
                                    const fileName = decodedUrl.split('/').pop();
                                    const actualFileName = fileName.substring(fileName.indexOf('_') + 1).split('?')[0];

                                    return (
                                        <a
                                            key={index}
                                            href={fileUrl}
                                            className="border-0 flex items-center"
                                            target="_blank"
                                            rel="noreferrer">
                                            <AttachFileIcon fontSize="sm" className="rotate-[20deg]" />
                                            {actualFileName}
                                        </a>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <Grid className={'-mt-8 sm:hidden sm:mt-4'}>
                        <Typography
                            className={`text-2xl ${
                                toggled ? 'blur-[10px]' : ''
                            }  font-bold font-geometric leading-6`}>{`${first_name} ${last_name}`}</Typography>
                        <Typography className="text-sm font-medium font-geometric mt-1">
                            {`${location.city} ${location.state}`}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item md={7}>
                    <div className="flex justify-between items-center">
                        <Typography
                            className={`hidden sm:block ${
                                toggled ? 'blur-[6px]' : ''
                            } text-2xl font-bold font-geometric`}>{`${first_name} ${last_name}`}</Typography>

                        <div className="font-geometric flex gap-x-2  text-base text-[#3C3C3C]">
                            <h4 className="sm:text-base text-sm">Application Date:</h4>
                            <p className="text-[#282828] text-sm sm:text-base font-medium">{formattedSubmittedDate}</p>
                        </div>
                    </div>
                    <Typography className="hidden sm:block text-sm font-medium font-geometric">
                        {`${location.city} ${location.state}`}
                    </Typography>
                    {high_school.id ? (
                        <div className="flex sm:flex-col flex-row justify-between lg:flex-row gap-y-1 mt-2 sm:gap-x-5">
                            <div className="font-geometric flex gap-x-2 text-base text-[#3C3C3C]">
                                <h4 className="sm:text-base text-sm">{isMobile ? 'HS:' : 'High School:'}</h4>
                                <Tooltip title={highSchoolName}>
                                    <p className="text-[#282828] break-words text-sm sm:text-base font-medium sm:w-[300px] w-[190px] text-ellipsis whitespace-nowrap overflow-hidden sm:mr-2">
                                        {highSchoolName}
                                    </p>
                                </Tooltip>
                            </div>

                            <div className="font-geometric flex gap-x-2  text-base text-[#3C3C3C]">
                                <h4 className="sm:text-base text-sm">{isMobile ? 'Grad Year:' : 'Grad Year:'} </h4>
                                <p className="text-[#282828] text-sm sm:text-base font-medium ">{`${highSchoolGradYear}`}</p>
                            </div>
                        </div>
                    ) : null}
                    {college.id ? (
                        <div className="flex sm:flex-col flex-row justify-between lg:flex-row gap-y-2 mt-2 sm:gap-x-5">
                            <div className="font-geometric flex gap-x-2 text-base text-[#3C3C3C]">
                                <h4 className="sm:text-base text-sm">{isMobile ? 'Col:' : 'College:'}</h4>
                                <Tooltip title={collegeName}>
                                    <p className="text-[#282828] break-words text-sm sm:text-base font-medium sm:w-[300px] w-[175px] text-ellipsis whitespace-nowrap overflow-hidden sm:mr-2">
                                        {collegeName}
                                    </p>
                                </Tooltip>
                            </div>

                            <div className="font-geometric flex gap-x-2  text-base text-[#3C3C3C]">
                                <h4 className="sm:text-base text-sm">{isMobile ? 'Grad Year:' : 'Grad Year:'} </h4>
                                <p className="text-[#282828] text-sm sm:text-base font-medium">{`${collegeGradYear}`}</p>
                            </div>
                        </div>
                    ) : null}
                    {(questions.question_1 || questions.question_2 || questions.question_3) && (
                        <YesNoQuestionsAccordian questions={questions} application={application} />
                    )}
                    <div className="font-geometric flex gap-x-2 items-center text-sm mb-5 lg:text-base text-[#3C3C3C] mt-2 sm:mt-1 ">
                        <KeyStateTableList keySats={key_stats} studentLevels={student_level} />
                    </div>
                </Grid>
                <Grid
                    container
                    md={2.5}
                    display={'flex'}
                    flexDirection={isMobile ? 'row' : 'column'}
                    alignContent={'center'}
                    justifyContent={statusCode == 'pending' ? 'center' : 'end'}
                    paddingX={!isMobile ? '10px' : ''}
                    rowGap={2}
                    columnGap={isMobile ? 2 : 0}
                    marginBottom={'20px'}>
                    {statusCode === 'accepted' && (
                        <>
                            <BubbleTooltip title={labelConst.COACH_KIM_TIP} description={labelConst.PAYMENT_TIP}>
                                <div>
                                    <Button
                                        sx={{ width: '100%' }}
                                        className="bg-[#F5CBBF] font-geometric  p-3 text-[#6F2813] text-sm font-semibold rounded-[4px]"
                                        onClick={handlePaymentModal}>
                                        Payment
                                    </Button>
                                </div>
                            </BubbleTooltip>
                            <BubbleTooltip
                                title={labelConst.COACH_KIM_TIP}
                                description={labelConst.COMPLETE_PROGRAM_TIP}>
                                <div>
                                    <MuiModal
                                        title={'Confirm Application'}
                                        message={labelConst.FINISH_POPUPBOX_MESSAGE}
                                        open={confirmFinishModal}
                                        handleConfirm={() =>
                                            undoRejectedOrCompleted({ id: application_id, status: 'Completed' })
                                        }
                                        handleClose={() => {
                                            setConfirmFinishModal(false);
                                        }}
                                    />
                                    <Button
                                        sx={{ width: '100%' }}
                                        onClick={() => {
                                            setConfirmFinishModal(true);
                                        }}
                                        className="bg-[#86DB89] font-geometric p-3 text-[#126A16] text-sm font-semibold rounded-[4px]">
                                        {getButtonTabValue(oppo_type)}
                                    </Button>
                                </div>
                            </BubbleTooltip>
                        </>
                    )}

                    <Button
                        onClick={() => {
                            undoRejectedOrCompleted({ id: application_id, status: 'Reviewed', role, username });
                            dispatch(setScrollBarPosition(window.scrollY));
                        }}
                        state={{ from: 'Candidates' }}
                        className="bg-primary font-geometric p-3 text-[#FFFFFF] text-sm font-semibold rounded-[4px]">
                        Review
                    </Button>
                    {(statusCode === 'accepted' || statusCode === 'rejected') && (
                        <Button
                            onClick={() => undoRejectedOrCompleted({ id: application_id, status: 'Pending' })}
                            className="bg-gray-200 font-geometric p-3 text-gray-600 text-sm font-semibold rounded-[4px]">
                            Reconsider
                        </Button>
                    )}
                    {statusCode === 'pending' && (
                        <>
                            <Button
                                onClick={(e) =>
                                    onAcceptOrRejectClicked({
                                        id: application_id,
                                        approve: true,
                                        value: 1,
                                        e,
                                        studentEmail: user?.email,
                                        studentUserName: username,
                                        status: 'Accepted',
                                    })
                                }
                                className="bg-[#86DB89] font-geometric  p-3 text-[#126A16] text-sm font-semibold rounded-[4px]">
                                Select
                            </Button>
                            <Button
                                onClick={() =>
                                    onAcceptOrRejectClicked({
                                        id: application_id,
                                        approve: false,
                                        value: 2,
                                        studentEmail: user?.email,
                                        studentUserName: username,
                                        status: 'Rejected',
                                    })
                                }
                                className="bg-[#F5CBBF] font-geometric  p-3 text-[#6F2813] text-sm font-semibold rounded-[4px]">
                                Reject
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
function LeaderApplicantCard({
    college,
    high_school,
    profile,
    user,
    statusCode,
    onAcceptOrRejectClicked,
    checked,
    undoRejectedOrCompleted,
    questions,
    application,
    setOpenDialogueBox,
    openDialogueBox,
}) {
    const { team } = application;
    const navigate = useNavigate();
    const { first_name, last_name, student_level, role } = user || {};
    const {
        profile_photo,
        location,
        key_stats,
        username,
        profile_completion_percentage: profileCompletion,
        profile_strength_percentage: profilesStrength,
    } = profile || {};
    const { name: highSchoolName, graduation_year: highSchoolGradYear } = high_school || {};
    const { name: collegeName, graduation_year: collegeGradYear } = college || {};
    const dispatch = useDispatch();
    const application_id = application.id;
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const handlePaymentModal = () => setOpenPaymentModal(!openPaymentModal);
    const [confirmFinishModal, setConfirmFinishModal] = useState(false);
    const formattedSubmittedDate = format(fromUnixTime(Number(application?.created_at)), 'MMM dd yyyy');
    return (
        <div key={application_id} className="max-w-[1100px] py-3 sm:p-7">
            <PaymentForm openPaymentModal={openPaymentModal} handlePaymentModal={handlePaymentModal} />
            <MuiModal
                open={openDialogueBox}
                title={'Confirmation Box'}
                message={labelConst.LIMIT_MESSAGE}
                handleConfirm={() => navigate(`/sponsor/${username}/scholarships`)}
                handleClose={() => setOpenDialogueBox(false)}
            />
            <Grid container columnGap={'20px'}>
                <Grid
                    item
                    md={2}
                    className={`flex ${isMobile ? 'flex-row items-center gap-5' : ''} justify-center items-center`}>
                    <div className="flex items-center flex-col">
                        <div className="relative">
                            <Headshot url={profile_photo} size={isMobile ? 75 : 150} />
                        </div>

                        {application.file && (
                            <div className="m-2 mx-auto">
                                <a
                                    href={application.file}
                                    className="border-0 flex items-center "
                                    target="_blank"
                                    rel="noreferrer">
                                    <AttachFileIcon fontSize="sm" className="rotate-[20deg]" />
                                    Attachment
                                </a>
                            </div>
                        )}
                    </div>
                    <Grid className={'-mt-8 sm:hidden sm:mt-4 '}>
                        <Typography className="text-2xl font-bold font-geometric leading-6 ">{`${first_name} ${last_name}`}</Typography>
                        <Typography className="text-sm font-medium font-geometric mt-1">
                            {`${location.city} ${location.state}`}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item md={7}>
                    <div className="flex justify-between items-center">
                        <Typography className="hidden sm:block text-2xl font-bold font-geometric">{`${first_name} ${last_name}`}</Typography>

                        <div className="font-geometric flex gap-x-2  text-base text-[#3C3C3C]">
                            <h4 className="sm:text-base text-sm">Application Date:</h4>
                            <p className="text-[#282828] text-sm sm:text-base font-medium">{formattedSubmittedDate}</p>
                        </div>
                    </div>

                    {high_school.id && user?.role === 'High School' && (
                        <div className="flex sm:flex-col flex-row justify-between lg:flex-row gap-y-1 mt-2 sm:gap-x-5">
                            <div className="font-geometric flex gap-x-2 text-base text-[#3C3C3C]">
                                <h4 className="sm:text-base text-sm">{isMobile ? 'HS:' : 'High School:'}</h4>
                                <Tooltip title={highSchoolName}>
                                    <p className="text-[#282828] break-words text-sm sm:text-base font-medium sm:w-[300px] w-[190px] text-ellipsis whitespace-nowrap overflow-hidden sm:mr-2">
                                        {user?.role === 'College' ? collegeName : highSchoolName}
                                    </p>
                                </Tooltip>
                            </div>
                        </div>
                    )}

                    <div className="font-geometric flex gap-x-2 text-base text-[#3C3C3C]">
                        Team or Club Name:{' '}
                        <p className="text-primary break-words text-sm sm:text-base font-medium sm:w-[300px] w-[190px] text-ellipsis whitespace-nowrap overflow-hidden sm:mr-2">
                            <a href={`/team/${team?.id}`} className="border-none">
                                {team?.name}
                            </a>{' '}
                        </p>
                    </div>

                    {(questions.question_1 || questions.question_2 || questions.question_3) && (
                        <YesNoQuestionsAccordian questions={questions} application={application} />
                    )}
                    <div className="font-geometric flex gap-x-2 items-center text-sm mb-5 lg:text-base text-[#3C3C3C] mt-2 sm:mt-1 ">
                        <KeyStateTableList keySats={key_stats} studentLevels={student_level} />
                    </div>
                </Grid>
                <Grid
                    container
                    md={2.5}
                    display={'flex'}
                    flexDirection={isMobile ? 'row' : 'column'}
                    alignContent={'center'}
                    justifyContent={statusCode == 'pending' ? 'center' : 'end'}
                    paddingX={!isMobile ? '10px' : ''}
                    rowGap={2}
                    columnGap={isMobile ? 2 : 0}
                    marginBottom={'20px'}>
                    {statusCode === 'accepted' && (
                        <>
                            <BubbleTooltip title={labelConst.COACH_KIM_TIP} description={labelConst.PAYMENT_TIP}>
                                <div>
                                    <Button
                                        sx={{ width: '100%' }}
                                        className="bg-[#F5CBBF] font-geometric  p-3 text-[#6F2813] text-sm font-semibold rounded-[4px]"
                                        onClick={handlePaymentModal}>
                                        Payment
                                    </Button>
                                </div>
                            </BubbleTooltip>
                            <BubbleTooltip
                                title={labelConst.COACH_KIM_TIP}
                                description={labelConst.COMPLETE_PROGRAM_TIP}>
                                <div>
                                    <MuiModal
                                        title={'Confirm Application'}
                                        message={labelConst.FINISH_POPUPBOX_MESSAGE}
                                        open={confirmFinishModal}
                                        handleConfirm={() =>
                                            undoRejectedOrCompleted({ id: application_id, status: 'Completed' })
                                        }
                                        handleClose={() => {
                                            setConfirmFinishModal(false);
                                        }}
                                    />
                                    <Button
                                        sx={{ width: '100%' }}
                                        onClick={() => {
                                            setConfirmFinishModal(true);
                                        }}
                                        className="bg-[#86DB89] font-geometric p-3 text-[#126A16] text-sm font-semibold rounded-[4px]">
                                        Finish
                                    </Button>
                                </div>
                            </BubbleTooltip>
                        </>
                    )}

                    <Button
                        onClick={() => {
                            undoRejectedOrCompleted({
                                id: application_id,
                                status: 'Reviewed',
                                role,
                                username,
                                teamId: team?.id,
                            });
                            dispatch(setScrollBarPosition(window.scrollY));
                        }}
                        state={{ from: 'Candidates' }}
                        className="bg-primary font-geometric p-3 text-[#FFFFFF] text-sm font-semibold rounded-[4px]">
                        Review
                    </Button>
                    {(statusCode === 'accepted' || statusCode === 'rejected') && (
                        <Button
                            onClick={() => undoRejectedOrCompleted({ id: application_id, status: 'Pending' })}
                            className="bg-gray-200 font-geometric p-3 text-gray-600 text-sm font-semibold rounded-[4px]">
                            Reconsider
                        </Button>
                    )}
                    {statusCode === 'pending' && (
                        <>
                            <Button
                                onClick={(e) =>
                                    onAcceptOrRejectClicked({
                                        id: application_id,
                                        approve: true,
                                        value: 1,
                                        e,
                                        studentEmail: user?.email,
                                        studentUserName: username,
                                        status: 'Accepted',
                                    })
                                }
                                className="bg-[#86DB89] font-geometric  p-3 text-[#126A16] text-sm font-semibold rounded-[4px]">
                                Accept
                            </Button>
                            <Button
                                onClick={() =>
                                    onAcceptOrRejectClicked({
                                        id: application_id,
                                        approve: false,
                                        value: 2,
                                        studentEmail: user?.email,
                                        studentUserName: username,
                                        status: 'Rejected',
                                    })
                                }
                                className="bg-[#F5CBBF] font-geometric  p-3 text-[#6F2813] text-sm font-semibold rounded-[4px]">
                                Reject
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}

function KeyStateTableList({ keySats, studentLevels }) {
    const studentLevel = getStudentLevel() || studentLevels;
    const { gpa = null } = keySats || {};
    let items = [];
    if (studentLevel === GRADUATE_PROGRAM) {
        const { graduate_degree, major_1, major_2, minor_1 = {} } = keySats || {};
        items = [
            { label: labelConst.GPA, value: gpa },
            { label: labelConst.GRAD_DEGREE, value: graduate_degree?.name || graduate_degree?.category?.name },
            { label: labelConst.UNDER_GRAD_MAJOR, value: major_1?.name || major_1?.category?.name },
            { label: labelConst.SECOND_UNDER_GRAD_MAJOR, value: major_2?.name || major_2?.category?.name },
            { label: labelConst.UNDER_GRAD_MINOR, value: minor_1?.name || minor_1?.category?.name },
        ];
    } else if (studentLevel === labelConst.COLLEGE) {
        const { major_1, major_2, minor_1 = {}, minor_2 } = keySats || {};
        items = [
            { label: labelConst.GPA, value: gpa },
            { label: labelConst.MAJOR, value: major_1?.name || major_1?.category?.name },
            { label: labelConst.SECOND_MAJOR, value: major_2?.name || major_2?.category?.name },
            { label: labelConst.MINOR, value: minor_1?.name || minor_1?.category?.name },
            { label: labelConst.SECOND_MINOR, value: minor_2?.name || minor_2?.category?.name },
        ];
    } else {
        const { sat_score, act_score, ap_credit_eligible, honors_credit_eligible } = keySats || {};
        items = [
            { label: 'GPA', value: gpa },
            { label: 'SAT Score', value: sat_score },
            { label: 'ACT Score', value: act_score },
            { label: 'AP Credit Eligible', value: ap_credit_eligible },
            { label: 'Honors Credit Eligible', value: honors_credit_eligible },
        ];
    }

    const renderItems = items
        .map(({ label, value }) => {
            return label && value ? (
                <Typography
                    key={label}
                    className={`text-[0.8rem] flex flex-grow last-of-type:flex-grow-0 bg-[#DDE7F5] justify-center items-center px-4 ${
                        label === 'GPA' ? 'md:px-4' : 'md:px-[10px]'
                    } rounded pt-2 pb-2 sm:pt-2 sm:pb-2 text-center font-geometric font-medium sm:text-[0.89rem]`}>
                    {isBoolean(value) ? label : `${label} - ${value}`}
                </Typography>
            ) : null;
        })
        .filter(Boolean);
    if (!items.length) return null;

    return <div className="flex sm:mt-5 mt-1 gap-y-2 gap-x-2 rounded flex-wrap">{renderItems}</div>;
}
