import { Box } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';

const BoxDivider = ({ className = 'pt-8 pb-8' }) => {
    return (
        <div className={className}>
            <Box
                sx={{
                    height: isMobile ? 15 : 30,
                }}
                className="bg-primary print:h-0.5"
            />
        </div>
    );
};

export default BoxDivider;
