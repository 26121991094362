import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    Paper,
    Radio,
    RadioGroup,
} from '@mui/material';
import Pagination, { paginationClasses } from '@mui/material/Pagination';
import PaginationItem, { paginationItemClasses } from '@mui/material/PaginationItem';
import { styled } from '@mui/material/styles';
import { startOfDay } from 'date-fns';
import { endOfDay, format, fromUnixTime, getUnixTime } from 'date-fns/esm';
import parse from 'html-react-parser';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../contexts/Auth';
import { useSnackbar } from '../../../hooks';
import emptyImage from '../../../images/scholarship.svg';
import {
    addNewMailChimpTag,
    addOpportunitiesImage,
    createSponsorScholarship as createSponsorScholarshipApi,
    deleteOpportunityImage as deleteOpportunityImageApi,
    deleteSponsorScholarship as deleteSponsorScholarshipApi,
    fetchColleges,
    getAllSubjects as getAllSubjectsApi,
    getColleges as getCollegesApi,
    getHighSchools as getHighSchoolsApi,
    getSponsorScholarships as getSponsorScholarshipsApi,
    getSubjectsCategories as getSubjectsCategoriesApi,
    getUser as getUserApi,
    lookupCityState as lookupCityStateApi,
    searchCollege as searchCollegesApi,
    searchHighSchool as searchHighSchoolApi,
    updateSponsorScholarship as updateSponsorScholarshipApi,
} from '../../../services/app/api';
import dialog, { default as dialogService } from '../../../services/app/dialog';
import { getDefaultPaginationValues } from '../../../services/app/pagination';
import { isObject, isString } from '../../../services/core/types';
import { setFileData, setHighSchoolValue } from '../../../store/genericSlice';
import {
    formatCurrencyRange,
    formatToUSMoney,
    generateGraduationYearRange,
    mandatoryField,
} from '../../../utils/GenericFunctions';
import {
    META_KEYS,
    SCHOLARSHIP_CANDIDATE_LIST,
    SCHOLARSHIP_TEAM_CANDIDATE_LIST,
    STOREFRONT_SCHOLARSHIP_CANDIDATE_LIST,
    STOREFRONT_SCHOLARSHIP_TYPES_LIST,
    STUDENT_SCHOLARSHIP_TYPES_LIST,
    TEAM_SCHOLARSHIP_TYPES_LIST,
    applyCriteriaList,
} from '../../../utils/GetConstants';
import * as labelConst from '../../../utils/GetLabels';
import mailchimpTags from '../../../utils/mailchimpTags';
import { BackButton } from '../../Common/BackButton';
import LookingFor from '../../Common/LookingFor/LookingFor';
import { SPONSOR_CANDIDATE_LIST, getOpportunityList } from '../../Common/LookingFor/LookingFor.utils';
import Autocomplete from '../../UI/Autocomplete';
import BlockingLoader from '../../UI/BlockingLoader';
import Button from '../../UI/Button';
import Container from '../../UI/Container';
import IconButton from '../../UI/IconButton';
import MenuItem from '../../UI/MenuItem';
import MuiDate from '../../UI/MuiDate';
import TextEditor from '../../UI/TextEditor/TextEditor';
import TextField from '../../UI/TextField';
import Typography from '../../UI/Typography';
import Upload from '../../UI/Upload';
import ScholarshipCard from './ScholarshipCard';

const StyledPagination = styled(Pagination)(() => ({
    [`&.${paginationClasses.root}`]: {
        marginBlock: 20,
    },
    [`& > .${paginationClasses.ul}`]: {
        justifyContent: 'flex-end',
    },
}));

const SUBLEVELS = {
    Babysitter: 1,
    'Lawn Mower': 1,
    'Pet Care': 1,
    'Snow Removal': 1,
    'Restaurant Help': 1,
    'Office Assistant': 1,
    Other: 1,
    General: 3,
    Influencers: 1,
    Intern: 3,
    'Private Lessons': 3,
    'Research Assistant': 3,
    Tutor: 3,
    Athletics: 4,
    'Performing Arts': 3,
    'Spirit Squads': 3,
};

const GRAD_SUBLEVLES = {
    'Athletics - Specific Sports': 5,
    'Performing Arts': 4,
    'Spirit Squads': 4,
    'Specific Fields of Study': 5,
    Aviation: 4,
    'Consumer Science': 4,
    Other: 4,
    Philosophy: 4,
    Religion: 4,
};

const RESEARCH_SUBLEVELS = {
    Aviation: 2,
    'Consumer Science': 2,
    Other: 2,
    Philosophy: 2,
    Religion: 2,
};

const EMPLOYMENT_SUBLEVELS = {
    'Part-time': 5,
    'Full-time': 3,
};

function validate(data, isSubmit) {
    const {
        title,
        description,
        deadline,
        start_at,
        eligibility_criteria,
        type,
        amount,
        location,
        allow_hs_grad_years,
        role,
        students_limit,
    } = data;
    function getLookingForFieldErrors() {
        if (!type) return {};
        const { value } = type;
        const { id, level } = SPONSOR_CANDIDATE_LIST[value];
        const optionId = `${id}_level`;
        const errors = {};

        const ResearchAssLevel =
            id === 'employment' && data[`${optionId}1`]?.value === 'Research Assistant'
                ? RESEARCH_SUBLEVELS[data[`${optionId}2`]?.value?.value] || 3
                : undefined;

        const PartTimeLevel =
            id === 'employment' && data[`${optionId}1`]?.value === 'Full-time'
                ? EMPLOYMENT_SUBLEVELS[data[`${optionId}1`]?.value?.value] || 3
                : undefined;

        const fieldLevel =
            ResearchAssLevel ||
            PartTimeLevel ||
            SUBLEVELS[data[`${optionId}2`]?.value] ||
            SUBLEVELS[data[`${optionId}1`]?.value] ||
            level;
        const finalLevel =
            id === 'gradAssistantship'
                ? GRAD_SUBLEVLES[data[`${optionId}4`]?.value?.value] ||
                  GRAD_SUBLEVLES[data[`${optionId}3`]?.value] ||
                  level
                : fieldLevel;
        for (let index = 0; index < finalLevel; index++) {
            const key = `${optionId}${index + 1}`;
            if (
                key === 'employment_level3' ||
                key === 'internship_level2' ||
                key === 'mentorship_level2' ||
                key === 'volunteer_level1' ||
                key === 'gradAssistantship_level2'
            ) {
                errors.location = data[key]?.value === 'On Site' && location == null ? 'Required' : '';
            }

            errors[key] =
                data[key] === null || (Array.isArray(data[key]?.value) && data[key]?.value?.length <= 0)
                    ? 'Required'
                    : '';
            if (errors[key].length > 0 || !isSubmit) {
                return errors;
            }
        }
        return errors;
    }

    const deadlieErrorMessage =
        deadline == null || (isString(deadline) && deadline.trim().length <= 0)
            ? 'Deadline is required field'
            : deadline?.toString() === 'Invalid Date'
            ? 'Invalid Date'
            : '';
    const startDateErrorMessage =
        start_at == null || (isString(start_at) && start_at.trim().length <= 0)
            ? 'Start date is required field'
            : start_at?.toString() === 'Invalid Date'
            ? 'Invalid Date'
            : '';
    function validateNumberFormat(input) {
        const regex = /^\d+-\d+$/;
        return regex.test(input);
    }

    function isNumber(input) {
        const regex = /^[0-9,-]*$/;
        return regex.test(input);
    }
    const regVal = validateNumberFormat(amount);
    const validNum = isNumber(amount);

    const requiredErrorValue = regVal || validNum;

    const isValidValue = (val) => val === 0 || val === null || val === '' || val === '0';
    const studentLimit = isValidValue(students_limit);

    return {
        title: title.trim().length <= 0 ? 'Title is required field' : '',
        description: description.trim().length <= 0 ? 'Description is required field' : '',
        deadline: deadlieErrorMessage,
        start_at: startDateErrorMessage,
        eligibility_criteria:
            eligibility_criteria == undefined || eligibility_criteria?.length <= 0 ? 'For Who is required field' : '',
        type: type == null || type?.value?.length <= 0 ? 'Type of Opportunity is required field' : '',
        allow_hs_grad_years:
            role === 'Storefront' && allow_hs_grad_years?.length <= 0 ? 'Specific grad year is required' : null,
        amount:
            type?.value === 'Volunteers'
                ? null
                : amount.trim().length > 15
                ? `Max. of 15 characters. ${amount.trim().length} / 15`
                : !requiredErrorValue
                ? 'Amount should be listed as a number or number range'
                : amount.length <= 0
                ? 'Amount is required field'
                : '',
        students_limit:
            type?.value === 'Scholars' && studentLimit
                ? 'The number of students should not be 0 or an empty field!'
                : null,
        ...getLookingForFieldErrors(),
    };
}

function prepareOrderedMetaWithLocation(selectedMetaData, locationInfo) {
    const metaData = [];
    const excludedLocationMetaData = selectedMetaData.filter(({ key }) => key !== META_KEYS.LOCATION);

    for (let index = 0; index < excludedLocationMetaData.length; index++) {
        const { key } = excludedLocationMetaData[index];
        metaData.push(excludedLocationMetaData[index]);
        if (key === META_KEYS.LOCATION_TYPE) {
            metaData.push(locationInfo);
            excludedLocationMetaData[index + 1].parent = META_KEYS.LOCATION;
        }
    }

    return metaData;
}

const initialData = {
    title: '',
    description: '',
    deadline: '',
    start_at: '',
    eligibility_criteria: null,
    type: null,
    amount: '',
    students_limit: null,
    opp_for: 'student',
    allow_zipcodes: null,
    allow_high_schools: null,
    allow_hs_grad_years: null,
    allow_clg_grad_years: null,
    apply_criteria: 'basic-profile-completion',
    amount_unit: 'year',
};

const limitApplyList = [
    {
        value: 'Zip Code',
        label: 'Zip Code',
    },
    {
        value: 'High School',
        label: 'High School',
    },
    {
        value: 'College',
        label: 'College',
    },
];
const limitApplyListWithoutCollege = [
    {
        value: 'Zip Code',
        label: 'Zip Code',
    },
    {
        value: 'High School',
        label: 'High School',
    },
];

function constructDataForScholarshipEdit(data) {
    const { metadata } = data;

    const scholarshipKeys = Object.keys(data);
    const editData = {};
    scholarshipKeys.forEach((key) => {
        let value = data[key];
        const isValueNumber = typeof value === 'number';
        if (isValueNumber) {
            value = String(value);
        } else if (key === 'eligibility_criteria') {
            value = { label: value, value };
        } else if (key === 'deadline' || key === 'start_at') {
            value = format(fromUnixTime(Number(value)), 'MM / dd / yyyy');
        } else if (key === 'metadata') {
            const { value: type } = metadata.find(({ key }) => key === META_KEYS.OPP_TYPE) || {};
            value = type != undefined ? { label: type, value: type } : null;
            editData['type'] = value;
            return;
        }
        editData[key] = value;
    });
    editData['metadata'] = metadata;
    editData['id'] = data['id'];
    return editData;
}

const initialSelectedInputs = {
    employment_level1: null,
    employment_level2: null,
    employment_level3: null,
    employment_level4: null,
    employment_level5: null,
    internship_level1: null,
    internship_level2: null,
    internship_level3: null,
    internship_level4: null,
    gradAssistantship_level1: null,
    gradAssistantship_level2: null,
    gradAssistantship_level3: null,
    gradAssistantship_level4: null,
    gradAssistantship_level5: null,
    mentorship_level1: null,
    mentorship_level2: null,
    mentorship_level3: null,
    recruits_level1: null,
    recruits_level2: null,
    recruits_level3: null,
    recruits_level4: null,
    sponsorship_level1: null,
    volunteer_level1: null,
    location: null,
    event_level1: null,
    event_level2: null,
    event_level3: null,
    event_level4: null,
    event_level5: null,
    event_level6: null,
    event_level7: null,
};

function ScholarshipsEdit() {
    const { getRole, getUserName, getProfileId, getEmail } = useAuth();
    const snackbar = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const { scholarshipDetails = [], pagination = {} } = data;
    const { totalPages, page: pageNumber, pageSize: currentPageSize } = pagination;
    const [page, setPage] = useState(pageNumber ?? 1);
    const [pageSize, setPageSize] = useState(currentPageSize ?? 10);
    const [isAddFormVisible, setAddFormVisible] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [editScholarshipData, setEditScholarshipData] = useState(undefined);

    useEffect(() => {
        callSponsorScholarshipDetails({ page, pageSize, status: 'all' });
    }, [page]);

    function callSponsorScholarshipDetails({ page, pageSize, status }) {
        const params = { params: { id: getProfileId() } };
        setIsLoading(true);
        getSponsorScholarshipsApi(params, { page, pageSize, status })
            .then((response) => {
                const scholarshipDetails = response.data?.data || [];
                const pagination = response.data?.paginate ?? {};
                setIsLoading(false);
                setData({ scholarshipDetails, pagination });
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
    }

    function handlePageChange(event, value) {
        setPage(value);
        setTimeout(() => {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }, 50);
    }

    function toggleAddForm() {
        const mailchimpPayload = {
            profileId: getProfileId(),
            data: {
                email: getEmail(),
                tags: [mailchimpTags.SPONSOR_STARTS_TO_ADD_OPPORTUNITY],
            },
        };
        addNewMailChimpTag(mailchimpPayload).catch(() => {});
        setAddFormVisible((prevVisible) => !prevVisible);
    }

    async function onDeleteClicked(scholarshipId) {
        const isDeleteConfirmed = await dialogService.confirm('Are you sure you want to delete this opportunity?');
        if (!isDeleteConfirmed) {
            return;
        }
        try {
            await deleteSponsorScholarshipApi({ id: getProfileId(), scholarshipId });
            callSponsorScholarshipDetails({ page: 1, pageSize, status: 'all' });
            setPage(1);
            snackbar.success('Opportunity Deleted Successfully');
        } catch (error) {
            console.error(error);
            snackbar.error('Error!, Could not Delete opportunity');
        }
    }

    function onEditClicked(scholarshipId, index) {
        setEditIndex(index);
        const editData = constructDataForScholarshipEdit(scholarshipDetails[index]);
        setEditScholarshipData(editData);
    }

    function renderPagination() {
        if (isAddFormVisible || editIndex !== -1) {
            return null;
        }

        return (
            <StyledPagination
                count={totalPages}
                page={page}
                className="font-geometric"
                onChange={handlePageChange}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                        sx={{
                            [`&.${paginationItemClasses.selected}`]: {
                                backgroundColor: '#A3E635',
                                fontWeight: 'bold',
                            },
                        }}
                    />
                )}
            />
        );
    }

    function renderScholarshipList() {
        if (scholarshipDetails.length <= 0) {
            return null;
        }

        return (
            <>
                {renderPagination()}
                <div className="lg:divide-y lg:divide-slate-200 lg:border-b-1 lg:border-slate-200">
                    {scholarshipDetails.map((data, index) => {
                        return (
                            <React.Fragment key={data.id}>
                                {editIndex === index ? (
                                    renderForm()
                                ) : (
                                    <ScholarshipCard
                                        scholarship={{ ...data }}
                                        onDelete={onDeleteClicked}
                                        onEdit={onEditClicked}
                                        index={index}
                                        isFormVisible={isAddFormVisible}
                                        isEditing={editIndex !== -1}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
                {renderPagination()}
            </>
        );
    }

    function renderScholarships() {
        const isEditingForm = editIndex !== -1;
        const areScholarshipsEmpty = scholarshipDetails.length <= 0;

        return (
            <div>
                {isAddFormVisible ? (
                    renderForm()
                ) : !isEditingForm && !areScholarshipsEmpty ? (
                    <div className="flex justify-end lg:mt-8">
                        <Button variant="contained" onClick={toggleAddForm}>
                            {labelConst.TEXT_ADD_OPPORTUNITY}
                        </Button>
                    </div>
                ) : null}
                {renderScholarshipList()}
            </div>
        );
    }

    function renderForm() {
        return (
            <OpportunityForm
                editIndex={editIndex}
                setEditIndex={setEditIndex}
                setAddFormVisible={setAddFormVisible}
                setPage={setPage}
                callSponsorScholarshipDetails={callSponsorScholarshipDetails}
                pageSize={pageSize}
                editScholarshipData={editScholarshipData}
                setEditScholarshipData={setEditScholarshipData}
            />
        );
    }

    function renderBack() {
        return <BackButton />;
    }

    function renderEmptyPlaceholder() {
        if (scholarshipDetails.length > 0 || isLoading || isAddFormVisible) {
            return null;
        }

        return (
            <div className="sm:mt-12 my-10">
                <img
                    src={emptyImage}
                    alt={labelConst.INSTRUCTION_SCHOLARSHIP_EMPTY}
                    className="h-[300px] mx-auto block sm:mt-16 mt-6"
                />
                <div className="container flex justify-center items-center">
                    <Button
                        variant="contained"
                        className="sm:mt-16 mt-6"
                        onClick={() => {
                            const mailchimpPayload = {
                                profileId: getProfileId(),
                                data: {
                                    email: getEmail(),
                                    tags: [mailchimpTags.SPONSOR_ADDS_FIRST_OPPORTUNITY],
                                },
                            };
                            addNewMailChimpTag(mailchimpPayload).catch(() => {});
                            setAddFormVisible(true);
                        }}>
                        {labelConst.LABEL_EMPTY_OPPORTUNITY}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <Container
            className="
            w-full px-2 lg:p-4 xl:p-0 xl:mx-auto xl:max-w-7xl  relative sm:mt-20
            ">
            {renderBack()}
            <Typography className="mt-6 font-geometric font-semibold text-primary sm:text-[2rem] text-[1rem]">
                {labelConst.SPONSOR_PROFILE_SCHOLARSHIPS_HEADING}
            </Typography>
            <div className="flex flex-col gap-1">
                <Typography className="font-geometric sm:text-lg text-primary">
                    Add your opportunities for your High School Students here
                </Typography>
                <Typography className="font-geometric sm:text-lg text-primary mt-[-3px]">
                    Post your high school scholarships, sponsorships, contests and events here so your students can
                    seamlessly search, review and apply.
                </Typography>
            </div>

            {!scholarshipDetails.length && (
                <Typography className="text-sm sm:text-lg font-geometric sm:mt-4 mt-1">
                    {labelConst.INSTRUCTION_SPONSOR_SCHOLARSHIP}
                </Typography>
            )}

            <div className="relative mt-5">
                <div className="lg:mt-8">
                    <BlockingLoader loading={isLoading} />
                </div>
                {renderEmptyPlaceholder()}
                {renderScholarships()}
            </div>
        </Container>
    );
}

function getOrderedMetaList({ opportuityMeta, key }) {
    const metaList = [];
    let parentKey = key;
    opportuityMeta.forEach((_, index) => {
        const parentMetaData = opportuityMeta.find(({ parent }) => parent === parentKey);
        if (!parentMetaData?.key) return;
        if (parentMetaData) metaList[index] = parentMetaData;
        parentKey = parentMetaData.key;
    });
    return metaList;
}

function getSelectedInputs(scholarshipData) {
    const { metadata } = scholarshipData;
    const opportuityMeta = metadata.filter(({ key }) => key !== META_KEYS.OPP_TYPE);
    const selectedInputs = { ...initialSelectedInputs };
    const { value: opportunityType, key } = metadata.find(({ key }) => key === META_KEYS.OPP_TYPE);
    let objKey = `${SPONSOR_CANDIDATE_LIST[opportunityType].id}_level`;

    const metaListInfo = getOrderedMetaList({ opportuityMeta, key });
    if (key === 'opp_type') {
        const locationMeta = opportuityMeta.find(({ key }) => key === META_KEYS.LOCATION);
        selectedInputs.location = locationMeta;
    }

    const metaList = metaListInfo.filter(({ key }) => key !== META_KEYS.LOCATION);

    //TODO: refactor
    for (let index = 0; index < metaList.length; index++) {
        const { key: metaKey, value } = metaList[index];
        if (metaKey === 'college_fields_subject' || metaKey === 'college_fields_cat') {
            metaList[index] = { ...metaList[index], value: { label: value, value } };
        }
        if (metaKey === 'sponsorship_type' || metaKey === 'tutor_sub_grad') {
            metaList[index] = { ...metaList[index], value: value.split(',') };
        }
        if (metaKey === 'location') {
            selectedInputs.location = metaList[index];
        } else {
            const key = `${objKey}${index + 1}`;
            selectedInputs[key] = metaList[index];
        }
    }

    const locationMeta = metaListInfo.find(({ key }) => key === META_KEYS.LOCATION);
    if (locationMeta) {
        selectedInputs.location = locationMeta;
    }

    return selectedInputs;
}

function OpportunityForm({
    editIndex,
    setEditIndex,
    setAddFormVisible,
    setPage,
    callSponsorScholarshipDetails,
    pageSize,
    editScholarshipData,
    setEditScholarshipData,
}) {
    const { getProfileId, getEmail, getUserName, getRole, getSubRole, askForSubscription } = useAuth();
    const [desValue, setDesValue] = useState(editScholarshipData?.description || '');
    const snackbar = useSnackbar();
    const [scholarshipData, setScholarshipData] = useState(editScholarshipData || { ...initialData });
    const dispatch = useDispatch();
    const fileData = useSelector((state) => state.genericSlice.fileData);
    const { name: highSchoolName, id: highSchoolId } = useSelector((state) => state.genericSlice.highSchoolValue);

    const [isLoading, setIsLoading] = useState(false);
    const [totalFees, setTotalFees] = useState(0);
    const [touched, setTouched] = useState({
        title: false,
        description: false,
        deadline: false,
        start_at: false,
        eligibility_criteria: false,
        type: false,
        amount: false,
        students_limit: false,
    });
    const [scholarshipErrors, setScholorshipErrors] = useState({
        title: '',
        description: '',
        deadline: '',
        start_at: '',
        eligibility_criteria: '',
        type: '',
        amount: '',
        eventDate: '',
        students_limit: 0,
        allow_hs_grad_years: [],
    });
    const isEditable =
        scholarshipData?.fee_status === 'PAID' &&
        (scholarshipData?.type.value === 'Scholars' || scholarshipData?.type?.value === 'Students to Sponsor');

    const [totalVal, setTotalVal] = useState();
    const addAmountFunc = (num, amount) => {
        if (!num && !amount) {
            return setTotalVal(0);
        }
        const result = (num || 1) * (amount || 1);
        setTotalFees(formatToUSMoney(result * 0.1 < 100 ? 100 : result * 0.1 > 500 ? 500 : result * 0.1));
        return setTotalVal(formatToUSMoney(result));
    };

    const [textLoading, setTextLoading] = useState(false);
    const [highSchools, setHighSchools] = useState([]);
    const [highSchool, setHighSchool] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [college, setCollege] = useState([]);

    const [inputValue, setInputValue] = useState('');
    const [arrayOfObjects, setArrayOfObjects] = useState([]);
    const [paginated, setPaginated] = useState();
    const [searchStr, setSearchStr] = useState('');
    const [selectOption, setSelectOption] = useState('');
    const [selectSpecificGradYear, setSelectSpecificGradYear] = useState([]);
    const [errorTrue, setErrorTrue] = useState(false);
    const [zipcodeError, setZipcodeError] = useState('');
    const todayYear = new Date().getFullYear();

    const loadStudent = () => {
        getUserApi({ params: { id: getProfileId() } })
            .then((response) => {
                const { high_school } = response?.data?.data;
                dispatch(setHighSchoolValue(high_school));
            })
            .catch(({ errorCode }) => dialog.error('Sorry, we could not load your details.'));
    };

    useEffect(() => {
        getSubRole() === 'Storefront' && loadStudent();
    }, []);

    const gardYearNUm = generateGraduationYearRange(3, 1)?.map((i) => todayYear + i);
    const listOfYears = gardYearNUm?.map((element) => {
        return { value: element };
    });

    const gardYearNumCollege = generateGraduationYearRange(6, 1)?.map((i) => todayYear + i);
    const listOfYearsCollege = gardYearNumCollege?.map((element) => {
        return { value: element };
    });

    const stringArray = scholarshipData?.allow_zipcodes?.split(';');

    const zipObjectValue = stringArray?.map((value) => {
        return { value };
    });
    const stringArrayGradYear = scholarshipData?.allow_hs_grad_years?.split(';');
    const stringArrayGradYearClg = scholarshipData?.allow_clg_grad_years?.split(';');

    const gradYearObjectValue = stringArrayGradYear?.map((value) => {
        return { value };
    });

    const gradYearObjectValueClg = stringArrayGradYearClg?.map((value) => {
        return { value };
    });

    useEffect(() => {
        if (
            scholarshipData?.allow_high_schools_list?.lenght > 0 &&
            highSchool?.length === 0 &&
            selectOption === 'High School'
        ) {
            setHighSchool(scholarshipData?.allow_high_schools_list);
            setSelectOption('High School');
        }
    }, [highSchool]);

    useEffect(() => {
        if (scholarshipData?.allow_colleges_list?.lenght > 0 && college?.length === 0 && selectOption === 'College') {
            setCollege(scholarshipData?.allow_colleges_list);
            setSelectOption('College');
        }
    }, [college]);

    const deleteObject = (objectId) => {
        const newData = arrayOfObjects.filter((obj, index) => index !== objectId);
        setArrayOfObjects(newData);
    };

    const lookupZipcode = (zipcode) => {
        lookupCityStateApi({ params: { zipcode } })
            .then((response) => {
                const res = response?.data?.data?.CityStateLookupResponse?.ZipCode?.Error?.Description;
                if (res !== 'Invalid Zip Code.') {
                    if (inputValue.trim() !== '') {
                        if (!arrayOfObjects.some((obj) => obj.value === inputValue.trim())) {
                            if (arrayOfObjects.length < 10) {
                                setArrayOfObjects((prevArray) => [...prevArray, { value: inputValue.trim() }]);
                                setInputValue(''); // Clear the input field
                                setErrorTrue(false);
                            }
                        } else {
                            setZipcodeError('Zip Code seems to be already added.');
                            setErrorTrue(true);
                        }
                    }
                } else {
                    setZipcodeError('Zip Code seems to be incorrect.');
                    setErrorTrue(true);
                }
            })
            .catch((error) => {})
            .finally(() => {});
    };

    const handleKeyDown = (event) => {
        const zipCodeValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(inputValue);
        if (!zipCodeValid) return null;
        if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
            lookupZipcode(inputValue);
        }
    };

    const loadHighSchoolsDefault = () => {
        setTextLoading(true);
        const data = { params: { page: 1 }, query: '' };
        getHighSchoolsApi({ data })
            .then((response) => {
                Array.isArray(response.data?.data) && setHighSchools(response.data?.data);
            })
            .finally(() => setTextLoading(false));
    };

    const loadHighSchools = () => {
        setTextLoading(true);
        const pagination = paginated ? { ...paginated } : getDefaultPaginationValues();
        const data = { params: { ...pagination }, query: '' };
        getHighSchoolsApi({ data })
            .then((response) => {
                if (response.data?.data?.length === 0) loadHighSchoolsDefault();
                else Array.isArray(response.data?.data) && setHighSchools([...highSchools, ...response.data?.data]);
            })
            .catch((err) => {})
            .finally(() => setTextLoading(false));
    };

    const searchHighSchools = (searchValue) => {
        setSearchStr(searchValue);
        if (searchValue) {
            setTextLoading(true);
            const data = { params: { pageSize: -1 }, query: searchValue };
            searchHighSchoolApi({ data })
                .then((res) => {
                    Array.isArray(res.data?.data) && setHighSchools(res.data?.data);
                })
                .finally(() => setTextLoading(false));
        }
    };

    const handleScroll = (event) => {
        var node = event.target;
        const scrollHeight = Math.round(node.scrollHeight - node.scrollTop);
        const clientHeight = Math.trunc(node.clientHeight);
        const bottom =
            scrollHeight === clientHeight || scrollHeight === clientHeight + 1 || scrollHeight === clientHeight - 1;
        if (bottom && !searchStr) {
            const pagination = paginated ? { ...paginated } : getDefaultPaginationValues();
            pagination.page = pagination.page + 1;
            setPaginated({ ...pagination });
        }
    };

    const loadCollegesDefault = () => {
        setTextLoading(true);
        const data = { params: { page: 1 }, query: '' };
        getCollegesApi({ data })
            .then((response) => {
                Array.isArray(response.data?.data) && setColleges(response.data?.data);
            })
            .finally(() => setTextLoading(false));
    };

    const loadColleges = () => {
        setTextLoading(true);
        const pagination = paginated ? { ...paginated } : getDefaultPaginationValues();
        const data = { params: { ...pagination }, query: '' };

        getCollegesApi({ data })
            .then((response) => {
                if (response.data?.data.length === 0) loadCollegesDefault();
                else Array.isArray(response.data?.data) && setColleges([...colleges, ...response.data?.data]);
            })
            .catch((err) => {})
            .finally(() => setTextLoading(false));
    };

    const searchColleges = (searchValue) => {
        setSearchStr(searchValue);
        if (searchValue) {
            setTextLoading(true);
            const data = { params: { pageSize: -1 }, query: searchValue };
            searchCollegesApi({ data })
                .then((res) => {
                    Array.isArray(res.data?.data) && setColleges(res.data?.data);
                })
                .finally(() => setTextLoading(false));
        }
    };

    useEffect(() => {
        if (paginated) {
            loadHighSchools();
            loadColleges();
        }
    }, [paginated]);

    useEffect(() => {
        if (!searchStr) {
            loadHighSchoolsDefault();
            loadCollegesDefault();
        }
    }, [searchStr]);

    const arrayToMatch = [
        'HS Graduating Senior Athletes',
        'HS Graduating Senior Students',
        'HS Graduating Senior Athletes',
    ];

    const isMatched = arrayToMatch.includes(scholarshipData?.eligibility_criteria?.value);

    useEffect(() => {
        addAmountFunc(scholarshipData.students_limit, scholarshipData.amount);
    }, [scholarshipData.amount, scholarshipData.students_limit]);

    const [lookingForList, setLookingForList] = useState(() => {
        if (!scholarshipData.type?.value) return [];
        return getOpportunityList({ opportunityType: scholarshipData.type.value });
    });

    const [selectedInputs, setSelectedInputs] = useState(() => {
        if (!scholarshipData.type?.value) return initialSelectedInputs;
        return getSelectedInputs(scholarshipData);
    });
    const [eventDate, setEventDate] = useState(
        selectedInputs?.event_level6?.value || selectedInputs?.event_level5?.value || ''
    );
    const prevScholarshipData = useRef(scholarshipData);

    React.useEffect(() => {
        if (scholarshipData?.allow_zipcodes?.length > 0) {
            setArrayOfObjects(zipObjectValue);
            setSelectOption('Zip Code');
        }
        if (scholarshipData?.allow_high_schools_list?.length > 0) {
            setHighSchool(scholarshipData?.allow_high_schools_list);
            setSelectOption('High School');
        }
        if (scholarshipData?.allow_colleges_list?.length > 0) {
            setCollege(scholarshipData?.allow_colleges_list);
            setSelectOption('College');
        }
        if (scholarshipData?.allow_hs_grad_years?.length > 0) {
            setSelectSpecificGradYear(gradYearObjectValue);
        }
        if (scholarshipData?.allow_clg_grad_years?.length > 0) {
            setSelectSpecificGradYear(gradYearObjectValueClg);
        }
        if (editIndex !== -1) {
            const {
                employment_level1,
                employment_level2,
                employment_level4,
                employment_level3,
                gradAssistantship_level3,
                gradAssistantship_level4,
                internship_level3,
                mentorship_level3,
                event_level1,
            } = selectedInputs;

            const shouldCallFieldSubjects =
                gradAssistantship_level3?.value === 'Specific Fields of Study' ||
                employment_level1?.value === 'Intern' ||
                employment_level1?.value === 'Full-time' ||
                scholarshipData.type?.value === 'Mentee' ||
                employment_level2?.value === 'Research Assistant';

            if (shouldCallFieldSubjects) {
                getAllSubjectsApi().then(({ data: subjectsData }) => {
                    const { id: fieldId } = subjectsData.data.find(
                        ({ name }) =>
                            name === gradAssistantship_level4?.value?.value ||
                            name === employment_level2?.value?.value ||
                            name === employment_level4?.value?.value ||
                            name === internship_level3?.value?.value ||
                            name === mentorship_level3?.value?.value ||
                            name === employment_level3?.value?.value
                    );

                    if (
                        (scholarshipData.type.value === 'Intern' || scholarshipData.type.value === 'Mentee') &&
                        fieldId
                    ) {
                        setLookingForList(
                            getOpportunityList({
                                opportunityType: scholarshipData.type.value,
                                subjects: subjectsData.data,
                                fieldValue:
                                    employment_level2?.value || employment_level1?.value || scholarshipData.type.value,
                            })
                        );
                        return;
                    }

                    getSubjectsCategoriesApi({ category: fieldId }).then(({ data: categories }) => {
                        setLookingForList(
                            getOpportunityList({
                                opportunityType: scholarshipData.type.value,
                                subjects: subjectsData.data,
                                categories: categories.data,
                            })
                        );
                    });
                });
            }

            if (event_level1?.value === 'Campus Event' || event_level1?.value === 'Virtual Event') {
                fetchColleges({ params: { page: 1, pageSize: -1 } }).then(({ data: collegeList }) => {
                    setLookingForList(
                        getOpportunityList({
                            opportunityType: 'Event',
                            collegesList:
                                event_level1?.value === 'Virtual Event'
                                    ? [{ name: 'Not Applicable', id: 'not_applicable' }, ...collegeList.data]
                                    : collegeList.data,
                            fieldValue: event_level1?.value,
                        })
                    );
                });
                getAllSubjectsApi().then(({ data: subjectsData }) => {
                    setLookingForList(
                        getOpportunityList({
                            opportunityType: 'Event',
                            subjects: subjectsData.data,
                            fieldValue: event_level1?.value,
                        })
                    );
                });
            }
            if (event_level1?.value === 'Official Visit') {
                getAllSubjectsApi().then(({ data: subjectsData }) => {
                    setLookingForList(
                        getOpportunityList({
                            opportunityType: 'Event',
                            subjects: subjectsData.data,
                            fieldValue: 'Official Visit',
                        })
                    );
                });
            }
        }
    }, []);

    function resetForm() {
        const touchedValues = { ...touched };
        const errors = { ...scholarshipErrors };
        Object.keys(scholarshipData).forEach((key) => {
            touchedValues[key] = false;
            errors[key] = '';
        });
        setScholarshipData({ ...initialData });
        setEditScholarshipData(undefined);
        setTouched(touchedValues);
        setScholorshipErrors(errors);
    }

    function resetFormAfterEdit() {
        setAddFormVisible(false);
        resetForm();
        setEditIndex(-1);
        setPage(1);
    }

    async function onAddScholarship() {
        let errors = await runAllValidations({ ...scholarshipData, ...selectedInputs }, touched, true);
        const canSubmitScholarship = Object.values(errors).every((errorVal) => errorVal.length <= 0);
        if (!canSubmitScholarship) {
            setScholorshipErrors(errors);
            return;
        }
        if (!moment(scholarshipData.deadline).isValid()) {
            setScholorshipErrors({ ...scholarshipErrors, deadline: 'Enter Valid Date.' });
            return;
        }
        if (!moment(scholarshipData.start_at).isValid()) {
            setScholorshipErrors({ ...scholarshipErrors, start_at: 'Enter Valid Date.' });
            return;
        }
        const endOfDayDate = endOfDay(new Date(scholarshipData.deadline));
        const deadlineDate = getUnixTime(endOfDayDate);
        const startOfDayDate = startOfDay(new Date(scholarshipData.start_at));
        const startDateValue = getUnixTime(startOfDayDate);

        const scholarshipTypes = scholarshipData.type.value.toString();
        const eligibleCandidate = scholarshipData.eligibility_criteria.value;

        const highSChoolGradYearString = selectSpecificGradYear
            ?.map((item) => {
                return item.value;
            })
            .join(';');

        const zipCodeInString = arrayOfObjects
            ?.map((item) => {
                return item.value;
            })
            .join(';');
        const highSchoolInStringId = highSchool
            ?.map((item) => {
                return item.id;
            })
            .join(';');
        const highCollegeInString = college
            ?.map((item) => {
                return item.id;
            })
            .join(';');

        const selectedValues = Object.values(selectedInputs).filter(Boolean);
        const selectedMetaValues =
            selectedInputs.gradAssistantship_level2?.value === 'On Site'
                ? prepareOrderedMetaWithLocation(selectedValues, { ...selectedInputs.location })
                : selectedValues;
        const finalSelectedInputs = selectedMetaValues.map((metdata) => {
            const { value: metaValue, key } = metdata;
            let value = isObject(metaValue) ? metaValue : metaValue.toString();
            if (value?.zipcode && typeof value?.zipcode !== 'string') {
                value.zipcode = value?.zipcode?.toString();
            }
            if (key === 'event_date') {
                const endOfDayDate = new Date(value);
                const eventDate = getUnixTime(endOfDayDate);
                return { ...metdata, value: value === null ? 'null' : eventDate.toString() };
            }
            if (key === 'url' && selectedInputs.employment_level3?.value === 'On Site') {
                return { ...metdata, parent: 'location' };
            }
            if (key === 'url' && selectedInputs.employment_level3?.value === 'Virtual') {
                return { ...metdata, parent: 'location_type' };
            }

            const finalMetaValue = value.city ? value : value.value;
            return { ...metdata, value: finalMetaValue || value };
        });

        const stringWithoutCommas = scholarshipData.amount.replace(/,/g, '');
        const amountVal =
            getSubRole() === 'Storefront' && scholarshipData.amount ? stringWithoutCommas : scholarshipData.amount;
        const sendIdOfHighSchool = getSubRole() === 'Storefront' ? highSchoolId : highSchoolInStringId;
        const metadata = [{ key: META_KEYS.OPP_TYPE, parent: null, value: scholarshipTypes }, ...finalSelectedInputs];
        const data = {
            ...scholarshipData,
            deadline: deadlineDate,
            start_at: startDateValue,
            apply_criteria:
                scholarshipTypes === 'Scholars' ? 'full-profile-completion' : scholarshipData?.apply_criteria,
            type: scholarshipTypes,
            eligibility_criteria: eligibleCandidate,
            amount: scholarshipData.amount === '' ? '0' : amountVal,
            description: desValue,
            file: scholarshipData.file,
            students_limit: scholarshipData.students_limit < 0 ? 0 : Number(scholarshipData.students_limit),
            allow_zipcodes: zipCodeInString ? zipCodeInString : null,
            allow_high_schools: sendIdOfHighSchool ? sendIdOfHighSchool : null,
            allow_colleges: highCollegeInString ? highCollegeInString : null,
            allow_hs_grad_years:
                sendIdOfHighSchool?.length > 0 && highSChoolGradYearString?.length > 0
                    ? highSChoolGradYearString
                    : null,
            allow_clg_grad_years:
                highCollegeInString?.length > 0 && highSChoolGradYearString?.length > 0
                    ? highSChoolGradYearString
                    : null,
            metadata,
        };
        if (data.question_1 === '') {
            data.question_1 = null;
        }

        if (data.question_2 === '') {
            data.question_2 = null;
        }
        if (data.question_3 === '') {
            data.question_3 = null;
        }
        delete data.is_closed;
        delete data.type;
        delete data.created_at;
        delete data.updated_at;
        delete data.file;
        delete data.total_valuation;
        delete data.fee_status;
        delete data.fee_amount;
        delete data.total_paid_fee;
        delete data.allow_high_schools_list;
        delete data.allow_colleges_list;

        const isEditInProgress = editIndex !== -1;

        const mailchimpPayload = {
            profileId: getProfileId(),
            data: {
                email: getEmail(),
                tags: [
                    `${mailchimpTags.SPONSOR_ADDS_OPPORTUNITY}-${data?.title}-${getUserName()}-${new Date()
                        .toJSON()
                        .slice(0, 10)}`,
                ],
            },
        };

        try {
            setIsLoading(true);
            const scholarshipId = data.id;
            delete data.id;
            const value = isEditInProgress
                ? await updateSponsorScholarshipApi({ id: getProfileId(), scholarshipId, data })
                : await createSponsorScholarshipApi({ id: getProfileId(), data });
            await addNewMailChimpTag(mailchimpPayload).catch(() => {});

            if (fileData) {
                addOpportunitiesImage({
                    data: { profileId: getProfileId(), sponsorshipId: value?.data?.data?.id, file: fileData },
                }).then(() => {
                    callSponsorScholarshipDetails({ page: 1, pageSize, status: 'all' });
                    setIsLoading(false);
                    snackbar.success('image uploaded successfully');
                    dispatch(setFileData(''));
                });
            } else {
                callSponsorScholarshipDetails({ page: 1, pageSize, status: 'all' });
            }

            resetFormAfterEdit();
            callSponsorScholarshipDetails({ page: 1, pageSize, status: 'all' });
            snackbar.success(isEditInProgress ? 'Opportunity updated successfully' : 'Added New Opportunity');
        } catch (error) {
            console.error(error);
            if (error.errorCode === 'RESOURCE_EXIST') {
                snackbar.error('Opportunity Already exists. Please choose different opportunity name.');
                return;
            }
            // snackbar.error(error.data?.message);
            snackbar.error(
                isEditInProgress
                    ? 'Error while Updating opportunity'
                    : 'Error! Please fix the highlighted areas and try again.'
            );
        }
    }

    const opportunitiesImageOnchnage = (row) => {
        dispatch(setFileData(row));
    };
    const [mediaHoverId, setMediaHoverId] = useState();

    const deleteOpportunityImageFunc = (imageId) => {
        deleteOpportunityImageApi({ id: getProfileId(), scholarshipId: imageId })
            .then(() => {
                callSponsorScholarshipDetails({ page: 1, pageSize, status: 'all' });
                snackbar.success('image deleted successfully');
            })
            .catch(() => snackbar.error('error while delelting'));
        resetFormAfterEdit();
        dispatch(setFileData({}));
    };

    function onScholarshipDataChange(event, fieldName, newValue) {
        const { name, value } = (fieldName && newValue) || !event ? { name: fieldName, value: newValue } : event.target;

        const newData = { ...scholarshipData, [name]: value };
        const touchedValues = { ...touched, [name]: true };
        if (name === 'type' && newValue.value) {
            setLookingForList(getOpportunityList({ opportunityType: value.value }));
            setSelectOption('');
            setHighSchool([]);
            setInputValue('');
            setArrayOfObjects([]);
        }
        if (
            prevScholarshipData.current.type === null ||
            prevScholarshipData.current.type?.value !== newData.type?.value
        ) {
            prevScholarshipData.current.type = newData.type;
            setSelectedInputs(initialSelectedInputs);
        }

        setScholarshipData((prevData) => {
            return { ...prevData, [name]: value };
        });
        setTouched((prevTouched) => {
            return { ...prevTouched, [name]: true };
        });
        runAllValidations(newData, touchedValues).then((errors) => {
            setScholorshipErrors(errors);
        });
    }

    function runAllValidations(data, touchedValues, isSubmit = false) {
        return new Promise((resolve) => {
            const errors = getTouchedFields(data, touchedValues, isSubmit);
            resolve(errors);
        });
    }

    function runLookingForValidations({ optionId, fieldValue }) {
        const touchedValues = { ...touched, [optionId]: true };
        runAllValidations({ ...scholarshipData, ...selectedInputs, [optionId]: fieldValue }, touchedValues).then(
            (errors) => {
                setScholorshipErrors(errors);
            }
        );
    }

    function getTouchedFields(data, touchedFields, isSubmit) {
        if (isSubmit) {
            const newTouched = { ...touched };
            Object.keys(newTouched).forEach((keyVal) => (newTouched[keyVal] = true));
            setTouched(newTouched);
        }
        const errors = { ...scholarshipErrors };
        const fieldErrors = validate(
            {
                ...data,
                role: getSubRole(),
                allow_clg_grad_years: selectSpecificGradYear,
                allow_hs_grad_years: selectSpecificGradYear,
                description: desValue,
            },
            isSubmit
        );

        Object.keys(data).forEach((key) => {
            const isFieldTouched = isSubmit || touchedFields[key];
            if (isFieldTouched && fieldErrors[key]) {
                errors[key] = fieldErrors[key];
                return;
            }
            errors[key] = '';
        });
        if (selectedInputs.event_level1?.value) {
            errors['amount'] = '';
        }

        errors.eventDate = '';
        if (
            selectedInputs.event_level1?.value === 'Campus Event' ||
            selectedInputs.event_level1?.value === 'Virtual Event'
        ) {
            const eventDateCheck =
                selectedInputs.event_level1?.value === 'Campus Event'
                    ? selectedInputs.event_level5?.value
                    : selectedInputs.event_level6?.value;
            if (!!eventDateCheck) {
                if (!moment(eventDateCheck).isValid()) {
                    errors.eventDate = 'Please enter a valid date';
                }
            }
        } else if (selectedInputs.event_level1?.value === 'Official Visit') {
            errors['event_level3'] = '';
        }
        return errors;
    }

    function onCancelFormClicked() {
        if (editIndex !== -1) {
            setEditIndex(-1);
        }
        setAddFormVisible(false);
        resetForm();
    }
    useEffect(() => {
        setEventDate(selectedInputs?.event_level6?.value || selectedInputs?.event_level5?.value || '');
    }, [selectedInputs?.event_level1]);

    useEffect(() => {
        if (selectedInputs?.event_level5?.key === 'url') {
            setSelectedInputs((initial) => ({
                ...initial,
                event_level6: {
                    ...initial['event_level6'],
                    parent: !!selectedInputs?.event_level5?.value ? 'url' : 'college_fields_cat',
                },
            }));
        }
    }, [selectedInputs?.event_level5]);

    const ScholarOrScholarships =
        askForSubscription() &&
        (scholarshipData?.type?.value === 'Scholars' || scholarshipData?.type?.value === 'Students to Sponsor') &&
        getSubRole() !== 'Storefront';

    const helperTextValue =
        (scholarshipData?.type?.value === 'Scholars' &&
            askForSubscription() &&
            getSubRole() !== 'Storefront' &&
            labelConst.SCHOLARSHIP_PAID_MESSAGE) ||
        (scholarshipData?.type?.value === 'Students to Sponsor' &&
            askForSubscription() &&
            getSubRole() !== 'Storefront' &&
            labelConst.SPONSORSHIP_PAID_MESSAGE);

    const placeholderValueAmount =
        scholarshipData?.type?.value === 'Scholars'
            ? labelConst.PLACEHOLDER_SCHOLARSHIP_AMOUNT
            : scholarshipData?.type?.value === 'Students to Sponsor'
            ? labelConst.PLACEHOLDER_SPONSORSHIP_AMOUNT
            : labelConst.PLACEHOLDER_OPPORTUNITY_AMOUNT;
    const titleForStorefrontScholarship = scholarshipData?.type?.value === 'Scholars' && getSubRole() === 'Storefront';
    const placeholderValueNumber =
        (scholarshipData?.type?.value === 'Scholars' && labelConst.PLACEHOLDER_SCHOLARSHIP_NUMBER) ||
        (scholarshipData?.type?.value === 'Students to Sponsor' && labelConst.PLACEHOLDER_SPONSORSHIP_NUMBER);

    const feeValue =
        'The Your29 fee to reveal is 10% of the total scholarship value beginning at $100 and up to a max of $500.';
    const opportunityTypes =
        scholarshipData.opp_for === 'student' && getSubRole() !== 'Storefront'
            ? STUDENT_SCHOLARSHIP_TYPES_LIST
            : scholarshipData.opp_for === 'student' && getSubRole() === 'Storefront'
            ? STOREFRONT_SCHOLARSHIP_TYPES_LIST
            : TEAM_SCHOLARSHIP_TYPES_LIST;
    const foundObject = (objectToFind) => opportunityTypes.find((item) => item.value === objectToFind.value);

    const limitList =
        scholarshipData?.eligibility_criteria?.value === 'HS Students Only'
            ? limitApplyListWithoutCollege
            : limitApplyList;
    const currentdate = new Date().toLocaleDateString();
    const compareDate = scholarshipData?.start_at?._d?.toLocaleDateString();
    const toSchedule = currentdate < compareDate;
    const buttonValue = toSchedule ? 'Schedule Post' : 'Post Now';

    const handleAmountChange = (event, newValue) => {
        const val = event.target.value;
        if (/^[0-9-,]*$/.test(val) && val?.length < 16) {
            if (!/--/.test(val)) {
                onScholarshipDataChange(event, 'amount', newValue);
            }
        }
    };

    return (
        <div className="flex mt-4 mb-4">
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <FormLabel className="text-xs" id="demo-radio-buttons-group-label">
                            Opportunity For
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            row
                            name="opp_for"
                            value={scholarshipData.opp_for}
                            onChange={(e) => {
                                setScholarshipData((ini) => ({
                                    ...ini,
                                    type: null,
                                    apply_criteria:
                                        e.target?.value === 'student' ? 'basic-profile-completion' : 'verified-user',
                                    eligibility_criteria: null,
                                }));
                                onScholarshipDataChange(e);
                            }}>
                            <FormControlLabel
                                disabled={editIndex !== -1}
                                value="student"
                                control={<Radio />}
                                label={getSubRole() === 'Storefront' ? 'Our High School Students' : 'Student'}
                            />
                            {getSubRole() !== 'Storefront' && (
                                <FormControlLabel
                                    disabled={editIndex !== -1}
                                    value="team"
                                    control={<Radio />}
                                    label="Team"
                                />
                            )}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className="mt-4">
                    <Autocomplete
                        id="type"
                        name="type"
                        classes={{ autocomplete: 'mt-1' }}
                        label={mandatoryField(labelConst.LABEL_SPONSOR_OPPORTUNITY)}
                        options={opportunityTypes}
                        getOptionLabel={(option) => option.label}
                        // getOptionDisabled={(option) => option.disabled}
                        renderOption={(props, option) =>
                            !option?.depreciated && (
                                <li {...props}>
                                    <Typography className="w-[200px] sm:w-full">{option.label}</Typography>
                                </li>
                            )
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={labelConst.PLACEHOLDER_SCHOLARSHIP_OPPORTUNITY}
                                error={scholarshipErrors.type.length > 0}
                                helperMessage={helperTextValue}
                                helperMessageBg={ScholarOrScholarships}
                            />
                        )}
                        onChange={(event, newValue) => onScholarshipDataChange(event, 'type', newValue)}
                        value={editIndex !== -1 ? foundObject(scholarshipData.type) : scholarshipData.type}
                        error={scholarshipErrors.type.length > 0}
                        errorMessage={scholarshipErrors.type}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        disabled={isEditable}
                    />
                </Grid>
                {!isMobile && (
                    <Grid item xs={12} md={6} className="mt-3">
                        <TextField
                            name="title"
                            classes={{ root: 'mt-1' }}
                            label={mandatoryField(labelConst.LABEL_SCHOLARSHIP_TITLE)}
                            placeholder={labelConst.PLACEHOLDER_SCHOLARSHIP_TITLE}
                            onChange={(e) => {
                                if (e?.target?.value?.length <= 60) {
                                    onScholarshipDataChange(e);
                                }
                            }}
                            value={scholarshipData.title}
                            error={scholarshipErrors.title.length > 0}
                            errorMessage={scholarshipErrors.title}
                            disabled={isEditable}
                            helperMessage={'Opportunity name length should be less than 60 characters'}
                        />
                    </Grid>
                )}

                {scholarshipData.type && scholarshipData.fee_status !== 'PAID' && (
                    <Grid item xs={12}>
                        <LookingFor
                            setSelectedInputs={setSelectedInputs}
                            selectedInputs={selectedInputs}
                            value={scholarshipData.type.value}
                            lookingForList={lookingForList}
                            setLookingForList={setLookingForList}
                            scholarshipErrors={scholarshipErrors}
                            runLookingForValidations={runLookingForValidations}
                        />
                    </Grid>
                )}
                {isMobile && (
                    <Grid item xs={12} md={6} className="mt-5">
                        <TextField
                            name="title"
                            classes={{ root: 'mt-1' }}
                            label={mandatoryField(labelConst.LABEL_SCHOLARSHIP_TITLE)}
                            placeholder={labelConst.PLACEHOLDER_SCHOLARSHIP_TITLE}
                            onChange={onScholarshipDataChange}
                            value={scholarshipData.title}
                            error={scholarshipErrors.title.length > 0}
                            errorMessage={scholarshipErrors.title}
                            disabled={isEditable}
                        />
                    </Grid>
                )}

                <Grid item xs={12} className="mt-4">
                    <Grid>
                        <Typography className="text-xs text-gray-500 mt-2 mb-1">
                            {mandatoryField('Description')}
                        </Typography>
                        {isEditable ? (
                            <Box className="h-40 overflow-scroll border-1 border-gray p-1">
                                <Typography className="text-gray-400">{parse(desValue)}</Typography>
                            </Box>
                        ) : (
                            <TextEditor
                                isDisabled={isEditable}
                                value={desValue}
                                onChange={(newContact) => setDesValue(newContact)}
                            />
                        )}

                        {scholarshipErrors.description && !desValue && (
                            <Typography className="text-red-500 text-[14px]">
                                {scholarshipErrors.description}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="mt-5">
                    <div className="flex flex-col sm:flex-row sm:gap-3 sm:items-center">
                        <div>
                            <MuiDate
                                disablePast={true}
                                defaultValue={scholarshipData?.start_at}
                                value={scholarshipData?.start_at ? scholarshipData?.start_at : null}
                                label={mandatoryField(labelConst.LABEL_SPONSOR_START_DATE)}
                                bottomMargin={true}
                                onChange={(newValue) => onScholarshipDataChange(undefined, 'start_at', newValue)}
                            />
                            {scholarshipErrors.start_at.length > 0 && (
                                <Typography className="text-red-500 text-[14px] ">
                                    {scholarshipErrors.start_at}
                                </Typography>
                            )}
                        </div>
                        <Typography className="sm:block hidden font-bold text-13 mt-5">-</Typography>
                        <div>
                            {/* //// */}
                            <MuiDate
                                disablePast={true}
                                defaultValue={scholarshipData?.deadline}
                                value={scholarshipData?.deadline ? scholarshipData?.deadline : null}
                                label={mandatoryField(labelConst.LABEL_SPONSOR_END_DATE)}
                                bottomMargin={true}
                                onChange={(newValue) => onScholarshipDataChange(undefined, 'deadline', newValue)}
                            />
                            {scholarshipErrors.deadline.length > 0 && (
                                <Typography className="text-red-500 text-[14px] ">
                                    {scholarshipErrors.deadline}
                                </Typography>
                            )}
                        </div>
                    </div>
                </Grid>
                {scholarshipData.type?.value === 'Event' && (
                    <Grid item xs={12} md={6} className="mt-5">
                        <MuiDate
                            bottomMargin={true}
                            value={
                                !!eventDate
                                    ? moment.unix(eventDate)
                                    : selectedInputs.event_level1?.value === 'Campus Event'
                                    ? selectedInputs?.event_level5?.value
                                    : selectedInputs?.event_level6?.value
                            }
                            label={mandatoryField('Event Date')}
                            variant={'DateTimePicker'}
                            disabled={isEditable}
                            onChange={(newValue) => {
                                setEventDate('');
                                if (selectedInputs.event_level1?.value === 'Campus Event') {
                                    setSelectedInputs((initial) => ({
                                        ...initial,
                                        event_level5: {
                                            key: 'event_date',
                                            value: newValue,
                                            parent: 'college_fields_cat',
                                        },
                                    }));
                                } else {
                                    setSelectedInputs((initial) => ({
                                        ...initial,
                                        event_level6: {
                                            key: 'event_date',
                                            value: newValue,
                                            parent: !!selectedInputs?.event_level5?.value
                                                ? 'url'
                                                : 'college_fields_cat',
                                        },
                                    }));
                                }
                            }}
                        />
                        {scholarshipErrors.eventDate.length > 0 && (
                            <Typography className="text-red-500 text-[14px] ">{scholarshipErrors.eventDate}</Typography>
                        )}
                    </Grid>
                )}
                {selectedInputs.employment_level1?.value === 'Full-time' && (
                    <Grid item xs={12} md={6} className="mt-4">
                        <TextField
                            disabled={isEditable}
                            type="text"
                            classes={{ root: 'mt-1' }}
                            name="Requisition Link"
                            label={'Requisition Link'}
                            placeholder="Add Link Here"
                            value={selectedInputs.employment_level4?.value}
                            onChange={(e) =>
                                setSelectedInputs((initial) => ({
                                    ...initial,
                                    employment_level4: {
                                        key: 'url',
                                        value: e.target.value,
                                        parent:
                                            selectedInputs.employment_level3?.value === 'On Site'
                                                ? 'location'
                                                : 'location_type',
                                    },
                                }))
                            }
                        />
                    </Grid>
                )}
                {selectedInputs?.event_level1?.value === 'Virtual Event' ? (
                    <Grid item xs={12} md={6} className="mt-4">
                        <TextField
                            disabled={isEditable}
                            name="Event Link"
                            type="text"
                            classes={{ root: 'mt-1' }}
                            placeholder={'Event Link'}
                            label={'Event Link'}
                            onChange={(e) =>
                                setSelectedInputs((initial) => ({
                                    ...initial,
                                    event_level5: { key: 'url', value: e.target.value, parent: 'college_fields_cat' },
                                }))
                            }
                            value={selectedInputs.event_level5?.value}
                        />
                    </Grid>
                ) : (
                    <>
                        {selectedInputs.event_level1?.value !== 'Campus Event' &&
                            scholarshipData?.type?.value !== 'Volunteers' && (
                                <Grid item xs={12} md={6} className="mt-4 flex flex-row gap-5 items-start">
                                    <TextField
                                        name="amount"
                                        classes={{ root: 'mt-1 w-full' }}
                                        type="text"
                                        placeholder={
                                            getSubRole() === 'Storefront'
                                                ? '5,000 or 1,500-3,000'
                                                : placeholderValueAmount
                                        }
                                        label={
                                            titleForStorefrontScholarship
                                                ? mandatoryField(labelConst.LABEL_STOREFRONT_SCHOLARSHIP_AMOUNT)
                                                : mandatoryField(labelConst.LABEL_SPONSOR_AMOUNT)
                                        }
                                        onChange={(event, newValue) => {
                                            if (getSubRole() === 'Storefront') {
                                                handleAmountChange(event, newValue);
                                            } else {
                                                if (Number(event.target.value) >= 0) {
                                                    onScholarshipDataChange(event, 'amount', newValue);
                                                }
                                            }
                                        }}
                                        value={
                                            getSubRole() === 'Storefront'
                                                ? formatCurrencyRange(scholarshipData.amount)
                                                : scholarshipData.amount
                                        }
                                        error={scholarshipErrors.amount.length > 0}
                                        errorMessage={scholarshipErrors.amount}
                                        disabled={scholarshipData?.type?.value === 'Volunteers' || isEditable}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        helperMessage={
                                            getSubRole() === 'Storefron'
                                                ? 'Please Inter in Valid Number.Ex- 1000-1000 or 1000'
                                                : ''
                                        }
                                    />
                                    {titleForStorefrontScholarship && (
                                        <TextField
                                            select
                                            classes={{ textfield: 'w-40 mt-[6px]' }}
                                            value={scholarshipData.amount_unit}
                                            onChange={(e) => onScholarshipDataChange(e, 'amount_unit', e.target.value)}
                                            label="Unit">
                                            <MenuItem value={'hr'}>per hr</MenuItem>
                                            <MenuItem value={'day'}>per day</MenuItem>
                                            <MenuItem value={'week'}>per week</MenuItem>
                                            <MenuItem value={'month'}>per month</MenuItem>
                                            <MenuItem value={'year'}>per year</MenuItem>
                                        </TextField>
                                    )}
                                </Grid>
                            )}
                    </>
                )}
                {selectedInputs?.internship_level3?.key === 'college_fields_cat' && (
                    <Grid item xs={12} md={6} className="mt-4">
                        <TextField
                            disabled={isEditable}
                            name="Event Link"
                            type="text"
                            classes={{ root: 'mt-1' }}
                            placeholder={'Event Link'}
                            label={'Event Link'}
                            onChange={(e) =>
                                setSelectedInputs((initial) => ({
                                    ...initial,
                                    internship_level4: {
                                        key: 'url',
                                        value: e.target.value,
                                        parent: 'college_fields_cat',
                                    },
                                }))
                            }
                            value={selectedInputs?.internship_level4?.value}
                        />
                    </Grid>
                )}

                {scholarshipData?.opp_for === 'student' && (
                    <Grid item xs={12} md={6} className="mt-3 hidden">
                        <TextField
                            id="select"
                            classes={{ root: 'mt-1' }}
                            select
                            label={'Application Criteria'}
                            value={scholarshipData.apply_criteria}
                            onChange={(e) => onScholarshipDataChange(e, 'apply_criteria', e.target.value)}>
                            {applyCriteriaList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                )}

                <Grid item xs={12} md={6} className="mt-4">
                    <Autocomplete
                        disabled={isEditable}
                        id="eligibility_criteria"
                        name="eligibility_criteria"
                        classes={{ autocomplete: 'mt-1' }}
                        label={mandatoryField(labelConst.LABEL_FOR_WHO)}
                        options={
                            scholarshipData?.opp_for === 'student' && getSubRole() !== 'Storefront'
                                ? SCHOLARSHIP_CANDIDATE_LIST
                                : scholarshipData.opp_for === 'student' && getSubRole() === 'Storefront'
                                ? STOREFRONT_SCHOLARSHIP_CANDIDATE_LIST
                                : SCHOLARSHIP_TEAM_CANDIDATE_LIST
                        }
                        renderInput={(params) => (
                            <TextField
                                error={scholarshipErrors.eligibility_criteria.length > 0}
                                noHelperText={true}
                                {...params}
                                placeholder=""
                            />
                        )}
                        onChange={(event, newValue) => onScholarshipDataChange(event, 'eligibility_criteria', newValue)}
                        value={scholarshipData.eligibility_criteria}
                        error={scholarshipErrors.eligibility_criteria.length > 0}
                        errorMessage={scholarshipErrors.eligibility_criteria}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </Grid>

                {scholarshipData?.eligibility_criteria && scholarshipData?.opp_for === 'student' && (
                    <Grid item xs={12} md={6} className="mt-[14px]">
                        <Autocomplete
                            multiple
                            label={
                                getSubRole() === 'Storefront'
                                    ? mandatoryField(`Select Specific Student Grad Years (up to 6)`)
                                    : `Select Specific Student Grad Years (up to 6)`
                            }
                            id="grad-year"
                            loadingText="Loading…"
                            sx={{ minWidth: '250px' }}
                            value={selectSpecificGradYear}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            options={isMatched ? listOfYears : listOfYearsCollege}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Typography className="w-[200px] sm:w-full">{option.value}</Typography>
                                </li>
                            )}
                            getOptionLabel={(option) => `${option.value}`}
                            renderInput={(params) => (
                                <TextField
                                    classes={{ root: 'mt-[2px]' }}
                                    error={
                                        getSubRole() === 'Storefront'
                                            ? scholarshipErrors.allow_hs_grad_years?.length > 0
                                            : false
                                    }
                                    noHelperText={true}
                                    {...params}
                                    placeholder=""
                                />
                            )}
                            onChange={(e, newValue) => setSelectSpecificGradYear(newValue)}
                            error={
                                getSubRole() === 'Storefront'
                                    ? scholarshipErrors.allow_hs_grad_years?.length > 0
                                    : false
                            }
                            errorMessage={getSubRole() === 'Storefront' ? scholarshipErrors.allow_hs_grad_years : ''}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6} className="mt-3">
                    <TextField
                        disabled={isEditable}
                        inputProps={{ min: 1 }}
                        id="students_limit"
                        name="students_limit"
                        classes={{ root: 'mt-1' }}
                        label={
                            scholarshipData?.type?.value === 'Scholars'
                                ? scholarshipData?.opp_for === 'student'
                                    ? mandatoryField('Number of Students')
                                    : mandatoryField('Number of Teams or Clubs')
                                : scholarshipData?.opp_for === 'student'
                                ? 'Number of Students'
                                : 'Number of Teams or Clubs'
                        }
                        type="text"
                        placeholder={placeholderValueNumber}
                        onChange={(event, newValue) => {
                            if (Number(event.target.value) > 0 || event.target.value === '') {
                                onScholarshipDataChange(event, 'students_limit', newValue);
                            }
                        }}
                        value={Number(scholarshipData.students_limit) < 0 ? 0 : scholarshipData.students_limit}
                        helperMessage={
                            scholarshipData.students_limit &&
                            ScholarOrScholarships &&
                            labelConst.SCHOLARSHIP_STUDENTS_NUMBER_MESSAGE
                        }
                        helperMessageBg={scholarshipData.students_limit && ScholarOrScholarships}
                        error={
                            scholarshipData?.type?.value === 'Scholars'
                                ? scholarshipErrors.students_limit.length > 0
                                : ''
                        }
                        errorMessage={scholarshipErrors.students_limit}
                    />
                </Grid>
                {(scholarshipData?.type?.value === 'Scholars' ||
                    scholarshipData?.type?.value === 'Students to Sponsor') && (
                    <Grid item xs={12} md={6} className="mt-3">
                        {scholarshipData?.opp_for === 'student' && getSubRole() !== 'Storefront' ? (
                            <TextField
                                id="select"
                                classes={{ root: 'mt-1' }}
                                select
                                label="Limit the Students Who Can Apply"
                                value={selectOption}
                                onChange={(e, newValue) => {
                                    setSelectOption(e.target.value);
                                }}>
                                {limitList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            <TextField
                                id="select"
                                classes={{ root: 'mt-1' }}
                                label="Limit the Students Who Can Apply"
                                value={'High School'}
                                disabled={true}
                                onChange={(e, newValue) => {
                                    setSelectOption((e.target = { value: 'High School' }));
                                }}
                            />
                        )}
                    </Grid>
                )}

                {scholarshipData?.opp_for === 'student' &&
                    getSubRole() === 'Storefront' &&
                    scholarshipData?.type?.value === 'Scholars' && (
                        <Grid item xs={12} md={6} className="mt-3">
                            <TextField
                                id="high school"
                                classes={{ root: 'mt-1' }}
                                label={'Limit the Students Who Can Apply'}
                                value={highSchoolName}
                                disabled={true}
                            />
                        </Grid>
                    )}

                {(scholarshipData?.type?.value === 'Scholars' ||
                    scholarshipData?.type?.value === 'Students to Sponsor') &&
                    selectOption === 'Zip Code' &&
                    getSubRole() !== 'Storefront' && (
                        <Grid item xs={12} md={6} className="mt-3">
                            <div className="gap-2 flex flex-col">
                                <TextField
                                    label="Add Zip Codes of Students (add up to 10)"
                                    inputProps={{ maxLength: 5 }}
                                    placeholder="Add up to 10 zip codes in which you want to select students from"
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    value={inputValue}
                                    maxLength="6"
                                    classes={{ root: 'mt-1' }}
                                    error={errorTrue}
                                    errorMessage={zipcodeError}
                                />
                                <ul className="grid grid-cols-3 sm:grid-cols-7 gap-2 ">
                                    {arrayOfObjects.map((item, index) => (
                                        <li
                                            className="bg-zinc-200 max-w-[5rem] rounded-full p-2 px-3 flex flex-row items-center justify-around gap-2"
                                            key={index}>
                                            <p className="text-12 text-black">{item.value}</p>
                                            <CloseIcon
                                                onClick={() => deleteObject(index)}
                                                className="text-17 cursor-pointer bg-zinc-400 font-semibold rounded-full text-white p-[1px]"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Grid>
                    )}
                {(scholarshipData?.type?.value === 'Scholars' ||
                    scholarshipData?.type?.value === 'Students to Sponsor') &&
                    selectOption === 'High School' &&
                    getSubRole() !== 'Storefront' && (
                        <Grid item xs={12} md={6} className="mt-3">
                            <Autocomplete
                                multiple
                                limitTags={10}
                                ListboxProps={{
                                    onScroll: (event) => {
                                        handleScroll(event);
                                    },
                                    role: 'list-box',
                                }}
                                label={`Select Specific High Schools (up to 10)`}
                                id="high-schools"
                                loading={textLoading}
                                loadingText="Loading…"
                                sx={{ minWidth: '250px' }}
                                onOpen={() => {
                                    loadHighSchools();
                                }}
                                onClose={() => {
                                    setHighSchools([]);
                                }}
                                value={highSchool}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                options={highSchools}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <Typography className="w-[200px] sm:w-full">
                                            {option.name} ({option.zipcode})
                                        </Typography>
                                    </li>
                                )}
                                getOptionLabel={(option) => `${option.name} (${option.zipcode})`}
                                renderInput={(params) => (
                                    <TextField
                                        classes={{ root: 'mt-1' }}
                                        {...params}
                                        placeholder="Type in up to 10 high school names or their zip codes in which you want to select students from"
                                        onChange={(e) => searchHighSchools(e.target.value)}
                                    />
                                )}
                                onChange={(e, newValue) => {
                                    if (newValue.length <= 10) {
                                        setHighSchool(newValue);
                                    }
                                }}
                                classes={{ root: 'mb-1' }}
                            />
                        </Grid>
                    )}

                {(scholarshipData?.type?.value === 'Scholars' ||
                    scholarshipData?.type?.value === 'Students to Sponsor') &&
                    selectOption === 'College' && (
                        <Grid item xs={12} md={6} className="mt-3">
                            <Autocomplete
                                multiple
                                limitTags={5}
                                ListboxProps={{
                                    onScroll: (event) => {
                                        handleScroll(event);
                                    },
                                    role: 'list-box',
                                }}
                                label={`Select Specific Colleges (up to 5)`}
                                id="high-schools"
                                loading={textLoading}
                                loadingText="Loading…"
                                sx={{ minWidth: '250px' }}
                                onOpen={() => {
                                    loadColleges();
                                }}
                                onClose={() => {
                                    loadColleges([]);
                                }}
                                value={college}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                options={colleges}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <Typography className="w-[200px] sm:w-full">
                                            {option.name} ({option.zipcode})
                                        </Typography>
                                    </li>
                                )}
                                getOptionLabel={(option) => `${option.name} (${option.zipcode})`}
                                renderInput={(params) => (
                                    <TextField
                                        classes={{ root: 'mt-1' }}
                                        {...params}
                                        placeholder="Type in up to 5 colleges names or their zip codes in which you want to select students from"
                                        onChange={(e) => searchColleges(e.target.value)}
                                    />
                                )}
                                onChange={(e, newValue) => {
                                    if (newValue.length <= 5) {
                                        setCollege(newValue);
                                    }
                                }}
                                classes={{ root: 'mb-1' }}
                            />
                        </Grid>
                    )}
                <Grid item xs={12} className="mt-4">
                    <Typography className="text-primary font-bold font-geometric">Yes/No Questions </Typography>
                    <p className="text-xs text-grayish font-geometric">
                        Add specific yes/no questions to remind applicants of eligibilty requirements, required profile
                        additions and required attachments to submit with the application. This will quickly eliminate
                        those who do not qualify.
                    </p>
                    <TextField
                        disabled={isEditable}
                        name={'question_1'}
                        type="text"
                        placeholder={
                            getRole() === 'Sponsor'
                                ? labelConst.SPONSOR_OPPORTUNITY_QUES_1
                                : labelConst.STOREFRONT_OPPORTUNITY_QUES_1
                        }
                        label={'Question 1'}
                        classes={{ root: 'mt-1' }}
                        onChange={(event, newValue) => onScholarshipDataChange(event, 'question_1', newValue)}
                        value={scholarshipData?.question_1}
                    />

                    <TextField
                        disabled={isEditable}
                        name={'question_2'}
                        type="text"
                        placeholder={
                            getRole() === 'Sponsor'
                                ? labelConst.SPONSOR_OPPORTUNITY_QUES_2
                                : labelConst.STOREFRONT_OPPORTUNITY_QUES_2
                        }
                        label={'Question 2'}
                        classes={{ root: 'mt-1' }}
                        onChange={(event, newValue) => onScholarshipDataChange(event, 'question_2', newValue)}
                        value={scholarshipData?.question_2}
                    />
                    <TextField
                        disabled={isEditable}
                        name={'question_3'}
                        type="text"
                        placeholder={
                            getRole() === 'Sponsor'
                                ? labelConst.SPONSOR_OPPORTUNITY_QUES_3
                                : labelConst.STOREFRONT_OPPORTUNITY_QUES_3
                        }
                        label={'Question 3'}
                        classes={{ root: 'mt-1' }}
                        onChange={(event, newValue) => onScholarshipDataChange(event, 'question_3', newValue)}
                        value={scholarshipData?.question_3}
                    />
                    {ScholarOrScholarships && (!!totalVal || !!scholarshipData.amount) && (
                        <div className="flex flex-col sm:flex-row sm:gap-4">
                            <Paper className="w-full bg-gray-100 p-5 rounded-lg mt-4" elevation={2}>
                                {!!totalVal && (
                                    <div className="flex items-center gap-2 font-geometric text-grayish">
                                        <span className="font-semibold">Total Scholarship Value :</span> {totalVal}
                                    </div>
                                )}
                                {!!totalFees && (
                                    <div className="flex items-center gap-2 font-geometric text-grayish">
                                        <span className="font-semibold">Total Fees:</span> {totalFees}
                                    </div>
                                )}
                                {!!scholarshipData.amount && (
                                    <div className="flex items-center gap-2 font-geometric text-grayish">
                                        <span className="font-semibold">Your29 Fee to Reveal Applicants :</span>{' '}
                                        {feeValue}
                                    </div>
                                )}
                            </Paper>
                        </div>
                    )}
                    <Grid className="mt-10">
                        <Typography>Upload Photo For Opportunity</Typography>
                        <Upload
                            // disable={disable}
                            allowedExtensions="image/*"
                            onChange={(e) => opportunitiesImageOnchnage(e.target.files[0])}
                            btnComponent={Button}
                            btnProps={{
                                variant: `contained`,
                                startIcon: <span className="material-icons material-icons-outlined">add_a_photo</span>,
                                title: 'Click here to upload an image.',
                                className: 'mt-2',
                                /* className: 'flex items-center justify-center bg-gray-50 cursor-pointer hover:bg-gray-100' */
                            }}
                            btnChildren="Upload"
                        />
                        {fileData?.name && <Typography>You have upload {fileData?.name}</Typography>}
                        {isLoading && <Typography>You have uploading Please wait {fileData?.name}</Typography>}
                        {scholarshipData.file && (
                            <div
                                variant="outlined"
                                className="relative flex justify-center items-center sm:w-64 sm:h-64 w-44 h-24 mt-2"
                                onMouseEnter={() => setMediaHoverId(1)}
                                onMouseLeave={() => setMediaHoverId(null)}>
                                <img
                                    className={[
                                        `object-contain rounded-md sm:w-64 sm:h-64 w-44 h-24`,
                                        mediaHoverId ? 'blur-sm' : '',
                                    ].join(' ')}
                                    src={scholarshipData.file}
                                    scholarshipData
                                    alt="opportunityImage"
                                />
                                {mediaHoverId && (
                                    <div className="absolute ">
                                        <IconButton
                                            disabled={true}
                                            title="Delete"
                                            className="text-white"
                                            onClick={() => deleteOpportunityImageFunc(scholarshipData.id)}>
                                            <span className="material-icons material-icons-outlined">delete</span>
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        )}
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="flex-end" className="mt-8">
                    <Button size="small" onClick={onCancelFormClicked}>
                        Cancel
                    </Button>
                    <Button
                        className="ml-4"
                        size="small"
                        variant="contained"
                        disabled={false}
                        onClick={onAddScholarship}>
                        {editIndex !== -1 ? 'Save' : `${buttonValue}`}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default ScholarshipsEdit;
