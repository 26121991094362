import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import EditIcon from '../../../images/edit-icon';
import { getProfileAttachmentsApi } from '../../../services/app/api';
import * as labelConst from '../../../utils/GetLabels';
import BoxDivider from '../../Common/BoxDivider';
import BlockingLoader from '../../UI/BlockingLoader';
import IconButton from '../../UI/IconButton';
import Typography from '../../UI/Typography';
const ProfileAttachments = ({
    editMode,
    id,
    renderOnlyAttachmentsImg = false,
    enrollId,
    opportunityName,
    oppAttachmentsImg,
}) => {
    const { getProfileId } = useAuth();
    const [attachments, setAttachments] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        load();
    }, []);

    function load() {
        setLoading(true);
        getProfileAttachmentsApi({ param: id })
            .then((resp) => setAttachments(resp.data?.data))
            .finally(() => setLoading(false));
    }

    const listItem = (arr, chunkSize) => {
        const result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            result.push(chunk);
        }
        return [...result];
    };

    const renderAttachments = (attachments, size) => {
        const list =
            attachments?.length >= 0 &&
            attachments?.map(({ id, title, file }) => {
                return (
                    <Grid key={id} item xs={size}>
                        <div className="mb-[0.6rem] md:mb-[0.45rem]">
                            <Typography className="break-words text-sm md:text-lg font-geometric font-semibold text-[#2398DA]">
                                <a className="border-none" href={file} target="_blank" rel="noreferrer">
                                    {title}
                                </a>
                            </Typography>
                        </div>
                    </Grid>
                );
            });
        return list;
    };

    const renderList = () => {
        if (attachments?.length <= 0) {
            return null;
        }
        let attachmentsArr = attachments?.length > 0 ? listItem(attachments, 3) : [];
        return (
            <>
                <Grid container spacing={{ sm: 1, md: 2 }}>
                    {!isMobile &&
                        attachmentsArr?.length > 0 &&
                        attachmentsArr?.map((attachments, index) => {
                            return (
                                <Grid container key={index} item xs={3}>
                                    {renderAttachments(attachments, 12)}
                                </Grid>
                            );
                        })}
                    {isMobile && renderAttachments(attachments, 6)}
                </Grid>
            </>
        );
    };
    {
        /* we need below  code for some functionality  */
    }

    // const sortAttachments = (attachments) => {
    //     return attachments?.slice().sort((a, b) => {
    //         const isAPDF = a?.split('?')[0].toLowerCase().endsWith('.pdf');
    //         const isBPDF = b?.split('?')[0].toLowerCase().endsWith('.pdf');
    //         return isBPDF - isAPDF; // Moves PDFs to the start
    //     });
    // };

    const sortAttachments = (attachments) => {
        return attachments.slice().sort((a, b) => {
            const isAPDF = a.file.split('?')[0].toLowerCase().endsWith('.pdf');
            const isBPDF = b.file.split('?')[0].toLowerCase().endsWith('.pdf');
            return isBPDF - isAPDF; // Moves PDFs to the start
        });
    };

    return !renderOnlyAttachmentsImg ? (
        <>
            <div className="flex justify-between items-center  mt-[26px] mb-4 sm:mb-[17px]">
                <Typography className="font-geometric font-extrabold text-base sm:text-[23px] text-primary">
                    Additional Attachments :
                </Typography>
                {editMode && (
                    <IconButton
                        className="bg-[#EDEDED] p-2 md:p-3 group print:hidden"
                        title="Edit your attachments"
                        component={Link}
                        to={`attachments`}>
                        <EditIcon />
                    </IconButton>
                )}
            </div>

            {attachments?.length === 0 && editMode && (
                <Typography className="font-geometric sm:text-xl text-center">
                    {labelConst.ADDITIONAL_INSTRUCTION}
                </Typography>
            )}

            <div>
                <BlockingLoader loading={loading} />
                <div>{renderList()}</div>
            </div>
        </>
    ) : (
        <>
            <div>
                <BoxDivider className="print:pt-[10px]  pt-5 pb-[1.15rem] lg:pb-[30px] lg:pt-7 print:pb-2" />
                <Typography
                    className={`print:text-sm font-geometric font-extrabold  text-[0.95rem] sm:text-[32px]  ${'text-primary'}`}>
                    Additional Attachments :
                </Typography>
                <div
                    className="flex flex-col gap-6 p-4"
                    // style={{ pageBreakBefore: 'always' }}
                >
                    {attachments?.length &&
                        sortAttachments(attachments)?.map((file, index) => {
                            // Extract file extension from URL
                            const urlWithoutQuery = file.file.split('?')[0]; // Remove query parameters
                            const isPDF = urlWithoutQuery.toLowerCase().endsWith('.pdf'); // Check extension

                            return (
                                <div
                                    key={index}
                                    // style={index !== 0 ? { pageBreakBefore: 'always' } : {}}
                                >
                                    {isPDF ? (
                                        <div className="flex flex-col items-center ">
                                            <h2 className="text-lg font-semibold mb-2">{file.title}</h2>
                                            <a
                                                href={file.file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-500 underline font-medium text-sm">
                                                View PDF
                                            </a>
                                        </div>
                                    ) : (
                                        <div
                                            className="flex flex-col items-center "
                                            style={{ pageBreakBefore: 'always' }}>
                                            {(enrollId || opportunityName) && (
                                                <div className="print:table print:w-full text-center bg-gray-200 py-1 border-b mb-4">
                                                    <h2 className="inline-block font-bold text-sm">
                                                        Enrl ID: {enrollId} | Opp. Name: {opportunityName}
                                                    </h2>
                                                </div>
                                            )}
                                            <h2 className="text-sm font-semibold mb-2 ">{file.title}</h2>
                                            <img
                                                src={file.file}
                                                alt={file.title}
                                                className="w-130 h-130 object-cover rounded-lg shadow-md"
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>

            {/*  we need below  code for some functionality   */}

            {/* <div>
            <BoxDivider className="pt-4 pb-[1.15rem] lg:pb-[30px] lg:pt-7 print:pb-4" />
            <Typography
                className={`print:text-[23px] font-geometric font-extrabold  text-[0.95rem] sm:text-[32px]  ${'text-primary'}`}>
                Additional Attachments :
            </Typography>

            {oppAttachmentsImg?.length &&
                sortAttachments(oppAttachmentsImg)?.map((file, index) => {
                    // Extract file extension from URL
                    const urlWithoutQuery = file?.split('?')[0]; // Remove query parameters
                    const isPDF = urlWithoutQuery?.toLowerCase()?.endsWith('.pdf'); // Check extension
                    const attachmentName = getFileNameFromUrl(file);
                    return (
                        <div key={index}>
                            {isPDF ? (
                                <div className="flex flex-col items-center">
                                    <h2 className="text-lg font-semibold mb-2">{attachmentName}</h2>
                                    <a
                                        href={file}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 underline font-medium">
                                        View PDF
                                    </a>
                                </div>
                            ) : (
                                <div className="flex flex-col items-center " style={{ pageBreakBefore: 'always' }}>
                                    <div className="print:table print:w-full text-center bg-gray-200 py-1 border-b mb-4">
                                        <h2 className="inline-block font-bold text-sm">
                                            Enrl ID: {enrollId} | Opp. Name: {opportunityName}
                                        </h2>
                                    </div>
                                    <h2 className="text-lg font-semibold mb-4 ">{attachmentName}</h2>
                                    <img
                                        src={file}
                                        alt={attachmentName}
                                        className="w-130 h-130 object-cover rounded-lg shadow-md"
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
        </div> */}
        </>
    );
};

export default ProfileAttachments;
